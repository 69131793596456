import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Checkbox} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CheckIcon from '@material-ui/icons/Check';
import green from "@material-ui/core/colors/green";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

// function createData(description, vendorcatalonumber, manufacturernumber ) {
//     return { description, vendorcatalonumber, manufacturernumber  };
// }
//
// const rows = [
//     createData('N95 M SMALL', '999-aa', '73773'),
//     createData('N95 M LRGE', '44593222', '33334'),
// ];

export default function TableForsave( props ) {

    const {
        handleButtonClickRemove,
        rows
    } = props

    const classes = useStyles();


    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">SELECT</TableCell>
                        <TableCell align="left"> QTY </TableCell>
                        <TableCell align="left">Description</TableCell>
                        <TableCell align="left">Vendor Cat</TableCell>
                        <TableCell align="left">SAVED</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (

                        <TableRow key={ row.rowindexer } >
                            <TableCell align="center">
                            {
                                 !row.saved  && <Button variant="contained" color="primary"
                                        onClick={handleButtonClickRemove(row)}
                                    //value={ row.vendorcatalognumber }
                                >REMOVE</Button>
                            }
                            {
                                row.saved && <CheckIcon style={{ background: green[500] }} align="center"></CheckIcon>
                            }

                            </TableCell>
                            <TableCell align="left">{row.qty}</TableCell>
                            <TableCell align="left">{row.description}</TableCell>
                            <TableCell align="left">{row.vendorcatalognumber }</TableCell>
                            <TableCell align="left">{row.rowindexer }</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
