/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cognito_identity_pool_id": "ca-central-1:4377a5bd-2b6b-4be4-8436-2f9f9de0af3f",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_rj3oMA3In",
    "aws_user_pools_web_client_id": "6mbq3sg2mk3b32j4hepaa3kb4v",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://tu76k0k6m3.execute-api.ca-central-1.amazonaws.com/gmhprod",
            "region": "ca-central-1"
        },
        {
            "name": "saveledgerentry",
            "endpoint": "https://60uwi5i9cj.execute-api.ca-central-1.amazonaws.com/gmhprod",
            "region": "ca-central-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "ca-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "ppetable-gmhprod",
            "region": "ca-central-1"
        },
        {
            "tableName": "locationinfo-gmhprod",
            "region": "ca-central-1"
        },
        {
            "tableName": "ppeledger-gmhprod",
            "region": "ca-central-1"
        }
    ],
    "aws_user_files_s3_bucket": "qwantifyppecartprofile230343-gmhprod",
    "aws_user_files_s3_bucket_region": "ca-central-1",
    "aws_content_delivery_bucket": "qwppedev-gmhprod",
    "aws_content_delivery_bucket_region": "ca-central-1",
    "aws_content_delivery_url": "https://d2bh5091qkv9n7.cloudfront.net"
};


export default awsmobile;
