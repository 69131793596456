import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from "@material-ui/core/TextField";


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});



export default function TableUsers( props ) {

    const {
        rows
    } = props

    const classes = useStyles();

    let hs;

    const  handlehs = (row) => (val) => {
        row.healthsystem = val.target.value;
    }

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">User</TableCell>
                        <TableCell align="left"> Email </TableCell>
                        <TableCell align="left"> HealthSystem </TableCell>
                        <TableCell align="left"> Input </TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map( (row) => (

                        <TableRow key={ row.user }>
                            <TableCell align="left">{ row.user } </TableCell>
                            <TableCell align="left">{ row.email } </TableCell>
                            <TableCell align="left">{ row.healthsystem } </TableCell>
                            <TableCell padding="checkbox">
                            <TextField className="textin" padding="checkbox"
                                       id="standard-multiline-flexible"
                                       value={ hs }
                                       onChange={ handlehs(row) }
                            />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
