import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import TableProt from "./TableProt";
import TableForsave from "./TableForsave";
import React, {useEffect, useState} from "react";
import {API, Auth} from "aws-amplify";
import MenuItem from "@material-ui/core/MenuItem";
import CircularIndeterminate from "./CircularIndeterminate";


export default function Cagetransfer( props ) {

    const [hospitalcode, setHospitalcode] = useState('') ;
    const [parentcode, setParentcode] = useState('');
    const [site, setSite] = useState('---');
    const [satelliteSelect, setSatelliteSelect] = useState('---') ;
    const [cageselect, setCageselect] = useState('---');
    const [cageselectto, setCageselectto] = useState('---');


    const [searchtext, setSearchtext] = useState('');
    const[wardselect, setWardselect] = useState('---');
    const[hospitalselect, setHospitalselect] = useState('---');

    const[user,setUser] = useState('---') ;


    const[rows, setRows] = useState([]);
    const[rowsforsave, setRowsforsave] = useState([]);
    const[row, setRow] = useState();

    const[satellites, setSatellites] = useState([]);
    const[cages, setCages] = useState([]) ;
    const[cagesto, setCagesto] = useState([]) ;

    const[wards, setWards] = useState([]);
    const[hospitals, setHospitals] = useState([]);

    const [isSaving, setIsSaving] = useState(false);
    const [rowindexer, setRowindexer] = useState(0);




    useEffect(() => {

        console.log('Receive useEffect');
        //getHospcode();
        //getlocationinfo();
        getParentcodefromCognito();


    }, [setSatellites, hospitalcode] );




    async function getledgerentry(row1) {

        console.log('getledgerentry');

        let apiName = 'saveledgerentry';
        let path = '/getinvlevelentry';

        const myInit = { // OPTIONAL
            body: {
                pk1: 'INVLEVEL#PARENTCODE#' + hospitalcode + 'HOSPITALCODE#' + hospitalcode,
                pk2: row1.vendorcatalognumber,
            },
            headers: {
                //'Content-Type' : 'application/json'
            },
        };

        let resp1 = await API.post(apiName, path, myInit );
        console.log('##FOUND RESP1', resp1.body.Items.length > 0  );

        if( resp1.body.Items.length > 0  ){
            updateinventorylevel( row1 ) ;
        }else{
            updateinsertinventorylevel(row1);
            //saveledgerentry( row1 ) ;
        }

        console.log(resp1);
    }

    async function savetransactional(row1) {
        console.log('@@@@@@@savetransactional ' );


        let apiName = 'saveledgerentry';
        let path = '/transactionmgr';

        const myInit = { // OPTIONAL
            body: {

                // pk1: 'INVLEVEL#PARENTCODE#' + hospitalcode + 'HOSPITALCODE#' + hospitalcode,
                // pk2: row1.vendorcatalognumber,
                pk11: 'INV#PARENTCODE#' + parentcode + 'HOSPITALCODE#' + hospitalcode,
                pk12: new Date(),


                pk13: 'INVLEVELCAGE#PARENTCODE#' + parentcode + 'HOSPITALCODE#' + hospitalcode + 'CAGE#'+ row1.cage,
                pk14: row1.vendorcatalognumber,

                pk23: 'INVLEVELCAGE#PARENTCODE#' + parentcode + 'HOSPITALCODE#' + hospitalcode + 'CAGE#'+ row1.cageto,
                pk24: row1.vendorcatalognumber,

                vendorcatalognumber:row1.vendorcatalognumber,
                //quantity: row1.qty,
                quantity:  parseInt(row1.qty,10 ),
                description: row1.description,
                hospitalcode:hospitalcode,
                parentcode:parentcode,
                status: 'CAGETRANSFER',
                ward:'---',
                stocknumber: row1.stocknumber ? row1.stocknumber : '---' ,
                unitofpurchase: row1.unitofpurchase ? row1.unitofpurchase : '---' ,
                cage:row1.cage,
                cageto:row1.cageto,
                satellite: row1.satellite,
                user:row1.user,
            },
            headers: {
                //'Content-Type' : 'application/json'
            },
        };

        let resp1 = await API.post(apiName, path, myInit );
        row1.saved = 1 ;
        console.log(resp1);

        //let resp2 = updateinventorylevel(row1);
        //console.log('resp2', resp2);

    }

    async function saveledgerentry(row1) {
        console.log('saveledgerentry');

        let apiName = 'saveledgerentry';
        let path = '/saveledgerentry';

        const myInit = { // OPTIONAL
            body: {
                pk1: 'INV#PARENTCODE#' + hospitalcode + 'HOSPITALCODE#' + hospitalcode,
                pk2: new Date(),
                vendorcatalognumber:row1.vendorcatalognumber,
                quantity:  parseInt(row1.qty,10 ),
                description: row1.description,
                hospitalcode:hospitalcode,
                parentcode:parentcode,
                status: 'CAGETRANSFER',
                ward:row1.ward,
                cage:row1.cage,
                cageto:row1.cageto,
                satellite: row1.satellite,
                user:row1.user,
                // pk2:'Mur',
                // vendorcatalognumber:'VC000123',
                // quantity: '23'
            },
            headers: {
                //'Content-Type' : 'application/json'
            },
        };

        let resp1 = await API.post(apiName, path, myInit );
        row1.saved = 1 ;
        console.log(resp1);

        //let resp2 = updateinventorylevel(row1);
        //console.log('resp2', resp2);

    }

    async function updateinsertinventorylevel(row1) {
        console.log('updateinventorylevel');

        let apiName = 'saveledgerentry';
        let path = '/updateinvlevel';

        const myInit = { // OPTIONAL
            body: {
                pk1: 'INVLEVEL#PARENTCODE#' + hospitalcode + 'HOSPITALCODE#' + hospitalcode,
                pk2: row1.vendorcatalognumber,
                vendorcatalognumber:row1.vendorcatalognumber,
                quantity:  parseInt(row1.qty,10 ),
                description: row1.description,

            },
            headers: {
                //'Content-Type' : 'application/json'
            },
        };

        let resp1 = await API.post(apiName, path, myInit );
        row1.saved = 1 ;
        console.log(resp1);

    }

    async function updateinventorylevel(row1) {
        console.log('updateinventorylevel');

        let apiName = 'saveledgerentry';
        let path = '/updateinvlevel';

        const myInit = { // OPTIONAL
            body: {
                pk1: 'INVLEVEL#PARENTCODE#' + hospitalcode + 'HOSPITALCODE#' + hospitalcode,
                pk2: row1.vendorcatalognumber,
                vendorcatalognumber:row1.vendorcatalognumber,
                quantity:  parseInt( '-'+row1.qty,10 ),
                description: row1.description,

            },
            headers: {
                //'Content-Type' : 'application/json'
            },
        };

        let resp1 = await API.put(apiName, path, myInit );
        row1.saved = 1 ;
        console.log(resp1);

    }

    async function setHospcode() {

        console.log('setHospcode');


        const user = await Auth.currentAuthenticatedUser();
        const result = await Auth.updateUserAttributes(user, {
            'custom:healthsystem': 'C1008'
        });
    }

    async function getHospcode() {
        console.log('getHospcode');
        const user = await Auth.currentAuthenticatedUser();
        let authinfo = await Auth.currentUserInfo();
        const favoriteFlavor = authinfo.attributes['custom:healthsystem'] ;
        setHospitalcode( favoriteFlavor );
    }

    async function getlocationinfo(pccode) {

        console.log('##1- getlocationinfo', hospitalcode);

        let apiName = 'saveledgerentry';
        let path = '/getlocinfo';

        const myInit = { // OPTIONAL
            body: {
                pccode: pccode,
            },
            headers: {
                //'Content-Type' : 'application/json',
                // Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        };

        console.log('2- getlocationinfo');
        //console.log(API.endpoint(apiName));

        let resp1 = await API.post(apiName, path, myInit );
        console.log(resp1);
        //setRows( [...resp1.body.Items] ) ;




        let optionItemsCage = resp1.body.Items.filter( function (e) {
            return e.pk2.startsWith('CAGE#');
        }).map( (e2) =>
            <MenuItem key={e2.pk2} value={e2.pk2}>{e2.friendlyname}</MenuItem>
        );
        optionItemsCage.push  ( <MenuItem key='---' value='---'>SELECT CAGE FROM</MenuItem>)
        setCages(optionItemsCage);

        let optionItemsCageto = resp1.body.Items.filter( function (e) {
            return e.pk2.startsWith('CAGE#');
        }).map( (e2) =>
            <MenuItem key={e2.pk2} value={e2.pk2}>{e2.friendlyname}</MenuItem>
        );
        optionItemsCageto.push  ( <MenuItem key='---' value='---'>SELECT CAGE TO </MenuItem>)
        setCagesto(optionItemsCageto);



        //wards
        let optionItemsWard = resp1.body.Items.filter( function (e) {
            return e.pk2.startsWith('WARD#');
        }).map( (e2) =>
            <MenuItem key={e2.pk2} value={e2.pk2}>{e2.friendlyname}</MenuItem>
        );
        optionItemsWard.push  ( <MenuItem key='---' value='---'>SELECT WARD</MenuItem>)
        setWards(optionItemsWard);

        //hosps
        let optionItemsHospital = resp1.body.Items.filter( function (e) {
            return e.pk2.startsWith('HOSP#');
        }).map( (e2) =>
            <MenuItem key={e2.pk2} value={e2.pk2}>{e2.friendlyname}</MenuItem>
        );
        optionItemsHospital.push  ( <MenuItem key='---' value='---'>SELECT HOSP</MenuItem>)
        setHospitals(optionItemsHospital);

        let optionItemsSat = resp1.body.Items.filter( function (e) {
            return e.pk2.startsWith('SAT#');
        }).map( (e2) =>
            <MenuItem key={e2.pk2} value={e2.pk2}>{e2.friendlyname}</MenuItem>
        );
        optionItemsSat.push ( <MenuItem key='---' value='---'>SELECT SAT</MenuItem>)
        setSatellites( optionItemsSat ) ;

    }

    async function getParentcodefromCognito() {
        console.log('getHospcode');
        const user1 = await Auth.currentAuthenticatedUser();
        setUser(user1);
        let authinfo = await Auth.currentUserInfo();
        const favoriteFlavor = authinfo.attributes['custom:healthsystem'] ;
        //setHospitalcode( favoriteFlavor );
        setParentcode(favoriteFlavor);
        console.log('parentcode from cognito', favoriteFlavor );
        await getlocationinfo(favoriteFlavor);


    }

    async function getppeitembydescription() {
        console.log('1- querybydescription');
        console.log('2 - SEARCH TEXT', searchtext);


        let apiName = 'getppeitem';
        let path = '/getppeitembydescription';

        const myInit = { // OPTIONAL
            body: {
                pk1: "C1008",
                sk1: '',
                description: searchtext
            },
            headers: {
                //'Content-Type' : 'application/json',
                // Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        };

        console.log('2- getppeitembydescription');
        //console.log(API.endpoint(apiName));

        let resp1 = await API.post(apiName, path, myInit );
        console.log(resp1);




    }

    async function searchDescription() {
        console.log('1- searchDescription');
        console.log('2 - search Description', searchtext);

        let apiName = 'getppeitem';
        let path = '/getppeitem';

        const myInit = { // OPTIONAL
            body: {
                pk1: "C1008",
                sk1: searchtext,
            },
            headers: {
                //'Content-Type' : 'application/json',
                // Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        };

        console.log('2- getppeitem');
        //console.log(API.endpoint(apiName));

        let resp1 = await API.post(apiName, path, myInit );
        console.log(resp1);
        //const returnedrows = [...resp1.body.Items] ;

        let somerows = [...resp1.body.Items] ;


    }


    async function loadpickall (){

        //alert ( ' pc ' + parentcode + ' hc ' + hospitalcode) ;

        console.log('tryquery') ;
        let apiName = 'saveledgerentry';
        let path = '/getledgerentries/getall';

        const myInit = {
            'queryStringParameters': {
                pk1: 'INVLEVELCAGE#PARENTCODE#' + parentcode + 'HOSPITALCODE#' + hospitalcode + cageselect,
            },
        };

        console.log('loadpickall' );
        let resp1 = await API.get(apiName, path, myInit );


        let rowstemp = [...resp1.body.Items] ;

        let counter = 0;
        for (const r1 of rowstemp) {

            r1.include = 1 ;
            r1.rowindexer = counter++;
        }

        setRows( rowstemp.sort((a, b) => a.description > b.description ? 1 : -1) ) ;


        //setRows([...resp1.body.Items]) ;
        //console.log('resp1: ', resp1 );

    }

    async function loadpick (){

        console.log('tryquery') ;
        let apiName = 'saveledgerentry';
        let path = '/getledgerentries';


        const myInit = {
            'queryStringParameters': {
                pk1: 'INVLEVELCAGE#PARENTCODE#' + hospitalcode + 'HOSPITALCODE#' + hospitalcode +  cageselect,
                pk2: searchtext,
            },
        };

        console.log('searchtext', searchtext);
        let resp1 = await API.get(apiName, path, myInit );
        setRows([...resp1.body.Items]) ;
        setSearchtext('' +
            '');

        console.log('resp1: ', resp1 );


    }




    async function search() {
        console.log('1- search INVLEVELS');
        console.log('2 - SEARCH TEXT', searchtext);

        let apiName = 'getppeitem';
        let path = '/getppeitem';

        const myInit = { // OPTIONAL
            body: {
                pk1: "C1008",
                sk1: searchtext,
            },
            headers: {
                //'Content-Type' : 'application/json',
                // Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        };

        console.log('2- getppeitem');
        //console.log(API.endpoint(apiName));

        let resp1 = await API.post(apiName, path, myInit );
        console.log(resp1);
        //const returnedrows = [...resp1.body.Items] ;

        if( resp1.body.Items && resp1.body.Items.length >0 ){
            console.log('response with vencat search - done');

            setRows( [...resp1.body.Items] ) ;
        }
        else{
            console.log('NO response trying description');
            getppeitembydescription();
        }
        setSearchtext('');
    }

    const handleWardchange = (val ) => {
        setWardselect(val.target.value);
    }

    const handleChangecage = ( val ) => {
        console.log('CAGE FROM');
        setCageselect( val.target.value );
    }
    const handleChangecageto = ( val ) => {
        console.log('CAGE TO') ;
        setCageselectto( val.target.value );
    }

    const handleChangeHospital = ( val1  ) => {
        console.log('dropdown change with', val1 );
        console.log('dropdown change with', val1.target.value );
        setSite( val1.target.value );

        console.log('$$$$ 1 hospitalcode ', val1.target.value );
        let index1 = val1.target.value.indexOf('#');
        console.log('$$$$hospitalcode index', index1 );
        let hc = val1.target.value.substring(index1 +1 ) ;
        console.log('####hc', hc );
        setHospitalcode( hc  ) ;
        //console.log('$$$$hospitalcode', hospitalcode   ) ;
        console.log('$$$$hospitalcode', hospitalcode) ;
    };

    const searchtextboxchange = (val2 ) => {
        console.log('###searchtextboxchange###');
        console.log( val2.target.value );

        if( val2.target.value ) {
            setSearchtext( val2.target.value.toUpperCase() );
        }
        else{
            setSearchtext('');
        }
    };

    const handleChangeSatellite = (val3) => {
        console.log('###change###');
        setSatelliteSelect(val3.target.value);
        //setSelectsatellite(val3.target.value );
    };

    const handleDetailButton = () =>  {
        //setDetailTabledisplay( false );
        console.log('APP Handle Detail Button ');
    }

    const handleButtonClickRemove = (prop) => (val) =>  {
        console.log('handleButtonClickRemove - prop: ', prop );
        let currow = prop ;

        let arrrowsforsave = [...rowsforsave]; // make a separate copy of the array

        let ar2 = arrrowsforsave.filter( item => item.rowindexer !== currow.rowindexer  );

        setRowsforsave( ar2 );
    }


    const handleButtonclick = (prop) => (val) =>  {
        //setDetailTabledisplay( false );
        //console.log('Table Says Handheld CheckBox: ', val.target.checked );
        console.log('handleButtonclick - prop: ', prop );


        // let currow = prop;
        //
        setRow(prop);

        if( prop.qty && /^\d+$/.test(prop.qty)  ){
            console.log('adding');

            setRowindexer( rowindexer+1 );
            prop.rowindexer=rowindexer ;

            rowsforsave.push(prop);


            setRows([]);
            setSearchtext('');
            setRowsforsave(rowsforsave);
            console.log('####pick rows for save', rowsforsave );
        }
    }

    const handleqtyfield = (prop) => (val) => {
        //console.log('Table Says QTY: ', row );
        console.log('From App.js Table Says QTY: ', val.target.value );
        console.log('prop: ', prop );
        let currow = prop;
        currow.qty = val.target.value;
        console.log( 'currow after qty: ', currow);
    }





    function createData(description, vendorcatalognumber, manufacturernumber ) {
        return { description, vendorcatalognumber, manufacturernumber  };
    }


    const tableAddButtonClick = (prop) => (val) =>  {

        let currow = prop ;
        console.log('currrow.rowidx', currow.rowindexer );

        try {

            let arrrowsforsave = [...rows];


            if (cageselectto === '---') {
                alert("SELECT A TO CAGE");
                setIsSaving(false);
                return;

            }

            console.log("####IM HERE");


            if (cageselect) {
                let index1 = cageselect.indexOf('#');
                if (index1 > 0) {
                    console.log('cageselect', index1);
                    currow.cage = cageselect.substring(index1 + 1);
                } else {
                    currow.cage = cageselect;
                }
            }
            if (cageselectto) {
                let index1 = cageselectto.indexOf('#');
                if (index1 > 0) {
                    console.log('cageselectTO', index1);
                    currow.cageto = cageselectto.substring(index1 + 1);
                } else {
                    currow.cageto = cageselectto;
                }
            }
            if (satelliteSelect) {
                let index1 = satelliteSelect.indexOf('#');
                if (index1 > 0) {
                    console.log('satselect', index1);
                    currow.satellite = satelliteSelect.substring(index1 + 1);
                } else {
                    currow.satellite = satelliteSelect;
                }
            }


            //setRow(prop);

            if (currow.inqty && /^\d+$/.test(currow.inqty)) {
                console.log('###adding');
                if (currow.qty) {
                    let a = Number(currow.qty);
                    let b = Number(currow.inqty);
                    let c = b;
                    currow.qty = String(c);
                }
                if (!currow.qty) {
                    currow.qty = currow.inqty;
                }
                if (currow.saved && currow.saved === 1) {
                    currow.saved = null;
                }

            let idx;
            for (const r1 of rows) {
                if (r1.rowindexer === currow.rowindexer) {
                    idx = rows.indexOf(r1);
                }
            }

            console.log('###idx', idx);

            currow.added = 1;

            arrrowsforsave[idx] = currow;
            setRows([...arrrowsforsave]);
            //setRow(currow);

        }
            else {
                alert( 'QTY must be a NUMBER' );
            }

        }catch( err ) {
            console.log(err) ;
        }

    }


    return (
        <div  className="Receive" >

            <main className="content" id="MAIN_CONTENT">

                <div className="inputs" >
                    <Select className="siteselect"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={site}
                            onChange={handleChangeHospital  }
                    >{hospitals}
                    </Select>

                    <Select className="selectsatellite"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={satelliteSelect}
                            onChange={handleChangeSatellite}
                    >{satellites}


                    </Select>

                    <Select className="cageselect"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={cageselect}
                            onChange={handleChangecage}
                    >
                        {cages}
                    </Select>


                    <Select className="cageselect"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={cageselectto}
                            onChange={handleChangecageto}
                    >
                        {cagesto}
                    </Select>


                </div>

                <div className="inputcomponents">
                    <TextField
                        onKeyPress={(ev) => {
                            console.log(`####Pressed keyCode ${ev.key} `);
                            if (ev.key === 'Enter') {
                                // Do code here
                                //ev.preventDefault();
                                //search();
                                //tryquery();
                                loadpick();
                                console.log('ENTER');
                            }
                        }}

                        className="textin"
                               id="standard-multiline-flexible"
                               label="Enter VENCAT or DESCRIPTION"
                               rowsMax="1"
                               value={searchtext}
                               onChange={searchtextboxchange}

                    />

                        <button className="searchbutton" variant="contained" color="primary" onClick={async () => {


                            if( !hospitalcode) {
                                alert('select a hospital') ;
                                return;
                            }

                            if(  cageselect  && cages.length > 1 &&  cageselect === '---') {
                                alert('select a cage') ;
                                return;

                                //
                            }
                            if(  !wardselect ) {
                                alert('select a WARD') ;
                                return;
                                //
                            }


                            const r = window.confirm("This will clear all your work - are you SURE?");

                            if( r ){
                                //sign Out logic
                                setIsSaving(true);

                            }else{
                                console.log('returning');
                                setIsSaving(false);
                                return ;
                            }


                            setRows([]);
                            console.log('SEARCHBUTTON');
                            //if (!searchtext) {
                                await loadpickall();
                                setIsSaving(false);
                            //} else {
                              // await loadpick();
                            //}

                            //getppeitembydescription();
                        }}>
                            REFRESH
                        </button>


                </div>
                <div className="audittables">

                    <TableProt  tableAddButtonClick={ tableAddButtonClick }  handleqtyfield={handleqtyfield}  rows={rows}   />

                    <h1>CAGE TRANSFER</h1>
                    {
                        rowsforsave.length > 0 &&
                        <TableForsave   handleButtonClickRemove={handleButtonClickRemove}  rows={rowsforsave}/>
                    }

                </div>
                {
                    !isSaving &&
                    <button className="searchbutton" variant="contained" color="primary" onClick={async () => {
                        //getlocationinfo();
                        //setHospcode();
                        setIsSaving(true);
                        for (const r1 of rows) {

                            r1.user = user.attributes.email;
                            console.log('@@@@@@****###user', r1.user);

                            console.log('r1', r1);
                            if(r1.added && !r1.saved) {
                                await savetransactional(r1) ;
                                // await saveledgerentry(r1);
                                // await getledgerentry(r1);
                                //saveledgerpickentry(r1);
                            }else{
                                console.log('saved already skipping', r1.vendorcatalognumber );
                            }

                        }

                        //setRows([]);
                        //setSearchtext('');
                        //setRowsforsave(rowsforsave);




                        //console.log('####pick rows for save', rowsforsave);
                        setIsSaving(false);
                    }}>
                        SAVE
                    </button>
                }
                {
                    isSaving &&
                    <CircularIndeterminate className="spinner"  ></CircularIndeterminate>
                }

            </main>

        </div>
    );


}
