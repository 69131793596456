import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Checkbox} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});



export default function TableGovtreport( props ) {

    const {
        handleqtyfield,
        handleButtonclick,
        rows,
        consumedMap,
        myMap,
        rowsMap
    } = props

    const classes = useStyles();

    let qty;
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Vendor Cat</TableCell>
                        <TableCell align="left">INV ON HAND </TableCell>
                        <TableCell align="left"> 24 HR CONSUMED </TableCell>
                        <TableCell align="left">GROUP </TableCell>
                        <TableCell align="left">Description</TableCell>
                        <TableCell align="left">Hospital Number</TableCell>
                        <TableCell align="left">Unit Of Issue</TableCell>



                    </TableRow>
                </TableHead>
                <TableBody>
                    { rows.map((row) => (

                        <TableRow key={row.rowindexer }>
                            <TableCell align="left">{row.vendorcatalognumber }</TableCell>
                            <TableCell align="left">{ myMap.get (row.vendorcatalognumber) }</TableCell>
                            <TableCell align="left">{ consumedMap.get(row.vendorcatalognumber) }</TableCell>
                            <TableCell align="left">{ rowsMap.get ( row.vendorcatalognumber ) }</TableCell>
                            <TableCell align="left">{row.description}</TableCell>
                            <TableCell align="left">{row.stocknumber}</TableCell>
                            <TableCell align="left">{row.unitofpurchase}</TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
