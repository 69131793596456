import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from "@material-ui/core/Backdrop";



export default function CircularIndeterminate() {

    return (
        <div>
                <CircularProgress />
        </div>
    );
}
