import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import TableProt from "./TableProt";
import TableForsave from "./TableForsave";
import React, {useEffect, useState} from "react";
import Amplify, {API, Auth} from "aws-amplify";
import MenuItem from "@material-ui/core/MenuItem";
import CircularIndeterminate from "./CircularIndeterminate";

import { withAuthenticator } from 'aws-amplify-react';
import awsconfig from '../aws-exports';
import TableUsers from "./TableUsers";

Amplify.configure(awsconfig);

export default function Useradmin( props ) {

    const [hospitalcode, setHospitalcode] = useState('') ;
    const [ parentcode, setParentcode] = useState('');
    const [site, setSite] = useState('---');
    const [satelliteSelect, setSatelliteSelect] = useState('---') ;
    const [cageselect, setCageselect] = useState('---');
    const [searchtext, setSearchtext] = useState('');
    const[wardselect, setWardselect] = useState('---');
    const[hospitalselect, setHospitalselect] = useState('---');
    const[user,setUser] = useState('') ;


    const [rows, setRows] = useState([]);

    let data;
    let myInit;
    let counter = 0;

    var allUsers = [] ;

    const getAllData = async ( limit ) => {


        console.log('######getAllData')  ;

        counter++ ;
        //console.log("Querying Table");
        //let data = await docClient.query(params).promise();

        let apiName = 'AdminQueries';
        let path = '/listUsers';
         myInit = {
            queryStringParameters: {
                "limit": limit,
                "token": nextToken
            },
            headers: {
                'Content-Type' : 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        const { NextToken, ...rest } =  await API.get(apiName, path, myInit);
        console.log('###REST', rest );

        allUsers = allUsers.concat ( [...rest.Users]  ) ;

        console.log('####NextToken', NextToken);

        if( NextToken ){
            nextToken = NextToken;
            return await getAllData(limit);
        }
        else{
            console.log('%%%% ALL DONE', allUsers );
            return allUsers;
        }
    }

    async function startAdmin(limit){

        let rest = await getAllData(limit);

        let rd = rest ;

        let userrows = [];

        for (const r1 of rd) {
            let user1 ={};
            user1.user = r1.Username;
            for ( const att of r1.Attributes ){
                if( att.Name === 'custom:healthsystem' ) {
                    user1.healthsystem = att.Value;
                }
                if( att.Name === 'email' ) {
                    user1.email = att.Value ;
                }
            }
            userrows.push( user1 );
        }
        setRows(...[userrows]);
        console.log( rows );

    }




    let nextToken;

    async function updateUserAttributes(username, healthsystem) {

        console.log('### updateUserAttributes ');
        //console.log( await Auth.currentAuthenticatedUser() );

        let apiName = 'AdminQueries';
        let path = '/updateUserAttributes';

        const myInit = {
            body: {
                username: username,
                attributename:'custom:healthsystem',
                attributevalue:healthsystem,
            },
            headers: {
                'Content-Type' : 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        const { NextToken, ...rest } =  await API.post(apiName, path, myInit);
        nextToken = NextToken;

        console.log('rest', rest );
        return rest;
    }

    let nextToken1;


    async function listUsers(limit){
        let apiName = 'AdminQueries';
        let path = '/listUsers';
        let myInit = {
            queryStringParameters: {
                "limit": limit,
                "token": nextToken
            },
            headers: {
                'Content-Type' : 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        const { NextToken, ...rest } =  await API.get(apiName, path, myInit);
        //nextToken1 = NextToken;

        console.log('####NextToken', NextToken);



        console.log('rest tk', rest );
        return rest;
    }

    async function listUsersold(limit){
        let apiName = 'AdminQueries';
        let path = '/listUsers';
        let myInit = {
            queryStringParameters: {
                "limit": limit,
                "token": nextToken
            },
            headers: {
                'Content-Type' : 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        const { NextToken, ...rest } =  await API.get(apiName, path, myInit);
        //nextToken1 = NextToken;

        console.log('####NextToken', NextToken);



        console.log('rest tk', rest );
        return rest;
    }



    function savecustom() {

        console.log('saving');

        for (const r1 of rows ) {
            try {

                console.log('user', r1.user);
                console.log('hs', r1.healthsystem);

                updateUserAttributes(r1.user,r1.healthsystem );
            }
            catch(err){
                console.log('err', err);
            }

        }

    }

    return (
        <div>
            <button onClick={() => startAdmin(10)}>List Users</button>
        <TableUsers   rows={rows}   />
        <button onClick={ () => savecustom() }>SAVE</button>
        </div>
);

}



