import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import TableProt from "./TableProt";
import TableForsave from "./TableForsave";
import React, {useEffect, useState} from "react";
import {API, Auth} from "aws-amplify";
import MenuItem from "@material-ui/core/MenuItem";
import CircularIndeterminate from "./CircularIndeterminate";


export default function Adjustments( props ) {

    const [hospitalcode, setHospitalcode] = useState('') ;
    const [site, setSite] = useState('---');
    const [satelliteSelect, setSatelliteSelect] = useState('---') ;
    const [cageselect, setCageselect] = useState('---');
    const [searchtext, setSearchtext] = useState('');
    const[wardselect, setWardselect] = useState('---');
    const[hospitalselect, setHospitalselect] = useState('---');

    const[rows, setRows] = useState([]);
    const[rowsforsave, setRowsforsave] = useState([]);
    const[row, setRow] = useState();

    const[satellites, setSatellites] = useState([]);
    const[cages, setCages] = useState([]) ;
    const[wards, setWards] = useState([]);

    const [ parentcode, setParentcode] = useState('');
    const[hospitals, setHospitals] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [user, setUser] = useState('');

    useEffect(() => {

        // console.log('Adjustments useEffect');
        // getHospcode();
        // getlocationinfo();

        console.log('Adjustment useEffect');
        getParentcodefromCognito();

    }, [setSatellites, hospitalcode] );

    async function getParentcodefromCognito() {
        console.log('getHospcode');
        const user1 = await Auth.currentAuthenticatedUser();
        setUser(user1);
        console.log('###user1', user1 );
        console.log('USER:', user);
        console.log('###user email', user.email );
        let authinfo = await Auth.currentUserInfo();


        const favoriteFlavor = authinfo.attributes['custom:healthsystem'] ;
        //setHospitalcode( favoriteFlavor );
        setParentcode(favoriteFlavor);
        console.log('parentcode from cognito', favoriteFlavor );
        await getlocationinfo(favoriteFlavor);
    }

    async function getledgerentry(row1) {

        console.log('getledgerentry');

        let apiName = 'saveledgerentry';
        let path = '/getinvlevelentry';

        const myInit = { // OPTIONAL
            body: {
                pk1: 'INVLEVEL#PARENTCODE#' + hospitalcode + 'HOSPITALCODE#' + hospitalcode,
                pk2: row1.vendorcatalognumber,
            },
            headers: {
                //'Content-Type' : 'application/json'
            },
        };

        let resp1 = await API.post(apiName, path, myInit );
        console.log('##FOUND RESP1', resp1.body.Items.length > 0  );

        if( resp1.body.Items.length > 0  ){
            updateinventorylevel( row1 ) ;
        }else{
            updateinsertinventorylevel(row1);
            //saveledgerentry( row1 ) ;
        }

        console.log(resp1);
    }




     async function saveledgerentry(row1) {
        console.log('saveledgerentry');

        let apiName = 'saveledgerentry';
        let path = '/transactionmgr';


        const myInit = { // OPTIONAL
            body: {
                // pk1: 'INVLEVEL#PARENTCODE#' + hospitalcode + 'HOSPITALCODE#' + hospitalcode,
                // pk2: row1.vendorcatalognumber,
                pk11: 'INV#PARENTCODE#' + parentcode + 'HOSPITALCODE#' + hospitalcode,
                pk12: new Date(),
                pk13: 'INVLEVELCAGE#PARENTCODE#' + parentcode + 'HOSPITALCODE#' + hospitalcode + 'CAGE#'+ row1.cage,
                pk14: row1.vendorcatalognumber,
                vendorcatalognumber:row1.vendorcatalognumber,
                //quantity: row1.qty,
                quantity:  parseInt(row1.qty,10 ),
                description: row1.description,
                hospitalcode:hospitalcode,
                parentcode:parentcode,
                stocknumber: row1.stocknumber ? row1.stocknumber : '---' ,
                unitofpurchase: row1.unitofpurchase ? row1.unitofpurchase : '---' ,
                status: 'ADJUSTMENT',
                ward:row1.ward,
                cage:row1.cage,
                satellite: row1.satellite,
                user:row1.user,
                // pk2:'Mur',
                // vendorcatalognumber:'VC000123',
                // quantity: '23'
            },
            headers: {
                //'Content-Type' : 'application/json'
            },
        };

        let resp1 = await API.post(apiName, path, myInit );
        row1.saved = 1 ;
        console.log('row1.saved', row1.saved );
        console.log(resp1);
        return resp1;

        //let resp2 = updateinventorylevel(row1);
        //console.log('resp2', resp2);

    }

    async function updateinsertinventorylevel(row1) {
        console.log('updateinventorylevel');

        let apiName = 'saveledgerentry';
        let path = '/updateinvlevel';

        const myInit = { // OPTIONAL
            body: {
                pk1: 'INVLEVEL#PARENTCODE#' + hospitalcode + 'HOSPITALCODE#' + hospitalcode,
                pk2: row1.vendorcatalognumber,
                vendorcatalognumber:row1.vendorcatalognumber,
                quantity:  parseInt(row1.qty,10 ),
                description: row1.description,
                hospitalcode:hospitalcode,
                parentcode:parentcode,
                ward:wardselect,
                cage:cageselect,
                satellite: satelliteSelect,
                user:row1.user,

            },
            headers: {
                //'Content-Type' : 'application/json'
            },
        };

        let resp1 = await API.post(apiName, path, myInit );
        row1.saved = 1 ;
        console.log(resp1);
        console.log('row1.saved', row1.saved );

    }

    async function updateinventorylevel(row1) {
        console.log('updateinventorylevel');

        let apiName = 'saveledgerentry';
        let path = '/updateinvlevel';

        const myInit = { // OPTIONAL
            body: {
                pk1: 'INVLEVEL#PARENTCODE#' + hospitalcode + 'HOSPITALCODE#' + hospitalcode,
                pk2: row1.vendorcatalognumber,
                vendorcatalognumber:row1.vendorcatalognumber,
                quantity:  parseInt(row1.qty,10 ),
                description: row1.description,
                hospitalcode:hospitalcode,
                parentcode:parentcode,
                ward:wardselect,
                cage:cageselect,
                satellite: satelliteSelect,
                user:row1.user,

            },
            headers: {
                //'Content-Type' : 'application/json'
            },
        };

        let resp1 = await API.put(apiName, path, myInit );
        row1.saved = 1 ;
        console.log('row1.saved', row1.saved );
        console.log(resp1);

    }

    async function setHospcode() {

        console.log('setHospcode');


        const user = await Auth.currentAuthenticatedUser();
        const result = await Auth.updateUserAttributes(user, {
            'custom:healthsystem': 'C1008'
        });
    }

    async function getHospcode() {
        console.log('getHospcode');
        const user = await Auth.currentAuthenticatedUser();
        let authinfo = await Auth.currentUserInfo();
        const favoriteFlavor = authinfo.attributes['custom:healthsystem'] ;
        setHospitalcode( favoriteFlavor );
    }


    async function getlocationinfo(pccode) {

        console.log('##1- getlocationinfo-parentcode', pccode );

        let apiName = 'saveledgerentry';
        let path = '/getlocinfo';

        const myInit = { // OPTIONAL
            body: {
                pccode: pccode,
            },
            headers: {
                //'Content-Type' : 'application/json',
                // Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        };

        console.log('2- getlocationinfo with parentcode', parentcode );
        //console.log(API.endpoint(apiName));

        let resp1 = await API.post(apiName, path, myInit );
        console.log(resp1);
        //setRows( [...resp1.body.Items] ) ;




        let optionItemsCage = resp1.body.Items.filter( function (e) {
            return e.pk2.startsWith('CAGE#');
        }).map( (e2) =>
            <MenuItem key={e2.pk2} value={e2.pk2}>{e2.friendlyname}</MenuItem>
        );
        optionItemsCage.push  ( <MenuItem key='---' value='---'>SELECT CAGE</MenuItem>)
        setCages(optionItemsCage);

        //wards
        let optionItemsWard = resp1.body.Items.filter( function (e) {
            return e.pk2.startsWith('WARD#');
        }).map( (e2) =>
            <MenuItem key={e2.pk2} value={e2.pk2}>{e2.friendlyname}</MenuItem>
        );
        optionItemsWard.push  ( <MenuItem key='---' value='---'>SELECT WARD</MenuItem>)
        setWards(optionItemsWard);

        //hosps
        let optionItemsHospital = resp1.body.Items.filter( function (e) {
            return e.pk2.startsWith('HOSP#');
        }).map( (e2) =>
            <MenuItem key={e2.pk2} value={e2.pk2}>{e2.friendlyname}</MenuItem>
        );
        optionItemsHospital.push  ( <MenuItem key='---' value='---'>SELECT HOSP</MenuItem>)
        setHospitals(optionItemsHospital);

        let optionItemsSat = resp1.body.Items.filter( function (e) {
            return e.pk2.startsWith('SAT#');
        }).map( (e2) =>
            <MenuItem key={e2.pk2} value={e2.pk2}>{e2.friendlyname}</MenuItem>
        );
        optionItemsSat.push ( <MenuItem key='---' value='---'>SELECT SAT</MenuItem>)
        setSatellites( optionItemsSat ) ;

    }

    async function getlocationinfo1() {

        console.log('##1- getlocationinfo', hospitalcode);

        let apiName = 'saveledgerentry';
        let path = '/getlocinfo';

        const myInit = { // OPTIONAL
            body: {
                pccode: hospitalcode,
            },
            headers: {
                //'Content-Type' : 'application/json',
                // Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        };

        console.log('2- getlocationinfo');
        //console.log(API.endpoint(apiName));

        let resp1 = await API.post(apiName, path, myInit );
        console.log(resp1);
        //setRows( [...resp1.body.Items] ) ;




        let optionItemsCage = resp1.body.Items.filter( function (e) {
            return e.pk2.startsWith('CAGE#');
        }).map( (e2) =>
            <MenuItem key={e2.pk2} value={e2.pk2}>{e2.friendlyname}</MenuItem>
        );
        optionItemsCage.push  ( <MenuItem key='---' value='---'>SELECT CAGE</MenuItem>)
        setCages(optionItemsCage);

        //wards
        let optionItemsWard = resp1.body.Items.filter( function (e) {
            return e.pk2.startsWith('WARD#');
        }).map( (e2) =>
            <MenuItem key={e2.pk2} value={e2.pk2}>{e2.friendlyname}</MenuItem>
        );
        optionItemsWard.push  ( <MenuItem key='---' value='---'>SELECT WARD</MenuItem>)
        setWards(optionItemsWard);

        //hosps
        let optionItemsHospital = resp1.body.Items.filter( function (e) {
            return e.pk2.startsWith('HOSP#');
        }).map( (e2) =>
            <MenuItem key={e2.pk2} value={e2.pk2}>{e2.friendlyname}</MenuItem>
        );
        optionItemsHospital.push  ( <MenuItem key='---' value='---'>SELECT HOSP</MenuItem>)
        setHospitals(optionItemsHospital);

        let optionItemsSat = resp1.body.Items.filter( function (e) {
            return e.pk2.startsWith('SAT#');
        }).map( (e2) =>
            <MenuItem key={e2.pk2} value={e2.pk2}>{e2.friendlyname}</MenuItem>
        );
        optionItemsSat.push ( <MenuItem key='---' value='---'>SELECT SAT</MenuItem>)
        setSatellites( optionItemsSat ) ;

    }

    async function getppeitembydescription() {
        console.log('1- querybydescription');
        console.log('2 - SEARCH TEXT', searchtext);


        let apiName = 'saveledgerentry';
        let path = '/getppeitembydescription';

        const myInit = { // OPTIONAL
            body: {
                pk1: "C1008",
                sk1: '',
                description: searchtext
            },
            headers: {
                //'Content-Type' : 'application/json',
                // Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        };

        console.log('2- getppeitembydescription');
        //console.log(API.endpoint(apiName));

        let resp1 = await API.post(apiName, path, myInit );
        console.log(resp1);
        setRows( [...resp1.body.Items] ) ;

    }

    async function searchDescription() {
        console.log('1- searchDescription');
        console.log('2 - search Description', searchtext);

        let apiName = 'saveledgerentry';
        let path = '/getppeitem';

        const myInit = { // OPTIONAL
            body: {
                pk1: "C1008",
                sk1: searchtext,
            },
            headers: {
                //'Content-Type' : 'application/json',
                // Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        };

        console.log('2- getppeitem');
        //console.log(API.endpoint(apiName));

        let resp1 = await API.post(apiName, path, myInit );
        console.log(resp1);
        //const returnedrows = [...resp1.body.Items] ;

        let somerows = [...resp1.body.Items] ;

    }


    async function search() {
        console.log('1- getppeitem');
        console.log('2 - SEARCH TEXT', searchtext);

        setRows([]);
        //setRowsfordisplay([]) ;

        let apiName = 'saveledgerentry';
        let path = '/getppeitem';

        const myInit = { // OPTIONAL
            body: {
                pk1: 'UNIQUEPRODUCT#PARENTCODE#'+ parentcode+ 'HOSPCODE#' + hospitalcode,
                sk1: searchtext,
            },
            headers: {
                //'Content-Type' : 'application/json',
                // Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        };

        console.log('2- getppeitem');
        //console.log(API.endpoint(apiName));

        let resp1 = await API.post(apiName, path, myInit );
        console.log(resp1);
        //const returnedrows = [...resp1.body.Items] ;

        let counter = 0;
        if( resp1.body.Items && resp1.body.Items.length >0 ){
            console.log('response with vencat search - done');

            let rowstemp = [...resp1.body.Items] ;

            for (const r1 of rowstemp) {

                r1.include = 1 ;
                r1.rowindexer = counter++;
            }

            setRows( rowstemp.sort((a, b) => a.description > b.description ? 1 : -1) ) ;
            //setRowsfordisplay( rowstemp.sort((a, b) => a.description > b.description ? 1 : -1) ) ;
        }

        setSearchtext('');
        setIsSaving(false);
    }

    async function search1() {
        console.log('1- getppeitem');
        console.log('2 - SEARCH TEXT', searchtext);


        let apiName = 'saveledgerentry';
        let path = '/getppeitem';

        const myInit = { // OPTIONAL
            body: {
                pk1: 'UNIQUEPRODUCT#PARENTCODE#'+ parentcode+ 'HOSPCODE#' + hospitalcode,
                sk1: searchtext,
            },
            headers: {
                //'Content-Type' : 'application/json',
                // Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        };

        console.log('2- getppeitem');
        //console.log(API.endpoint(apiName));

        let resp1 = await API.post(apiName, path, myInit );
        console.log(resp1);
        //const returnedrows = [...resp1.body.Items] ;

        if( resp1.body.Items && resp1.body.Items.length >0 ){
            console.log('response with vencat search - done');

            setRows( [...resp1.body.Items] ) ;
        }
        // else{
        //     console.log('NO response trying description');
        //     getppeitembydescription();
        // }
        setSearchtext('');
    }

    const handleWardchange = (val ) => {
        setWardselect(val.target.value);
    }

    const handleChangecage = ( val ) => {
        setCageselect( val.target.value );
    }

        const handleChangeHospital = async ( val1  ) => {
            console.log('dropdown change with', val1 );
            console.log('dropdown change with', val1.target.value );
            setSite( val1.target.value );
            console.log('$$$$ 1 hospitalcode ', val1.target.value );
            let index1 = val1.target.value.indexOf('#');
            console.log('$$$$hospitalcode index', index1 );
            let hc = val1.target.value.substring(index1 +1 ) ;
            console.log('####hc', hc );
            setHospitalcode( hc  ) ;
            //console.log('$$$$hospitalcode', hospitalcode   ) ;
        };


    const handleChange2 = (val2 ) => {
        console.log('###change###');

        if( val2.target.value ) {
            setSearchtext( val2.target.value.toUpperCase() );
        }
        else{
            setSearchtext('');
        }

        //search();
    };

    const handleChangeSatellite = (val3) => {
        console.log('###change###');
        setSatelliteSelect(val3.target.value);
        //setSelectsatellite(val3.target.value );
    };

    const handleDetailButton = () =>  {
        //setDetailTabledisplay( false );
        console.log('APP Handle Detail Button ');
    }

    const handleButtonClickRemove = (prop) => (val) =>  {
        console.log('handleButtonClickRemove - prop: ', prop );
        let currow = prop ;

        let arrrowsforsave = [...rowsforsave]; // make a separate copy of the array

        let ar2 = arrrowsforsave.filter( item => item.vendorcatalognumber !== currow.vendorcatalognumber  );

        setRowsforsave( ar2 );
    }


    const tableAddButtonClick = (prop) => (val) =>  {


        let currow = prop ;
        console.log('currrow.rowidx', currow.rowindexer );

        try {

            let arrrowsforsave = [...rows];

            if (wardselect) {
                //currow.ward = wardselect.indexOf('WARD#');
                let index1 = wardselect.indexOf('#');
                if(index1 > 0 ) {
                    console.log('$$$$wardselectdex', index1);
                    currow.ward = wardselect.substring(index1 + 1);
                }else{
                    currow.ward=wardselect;
                }

            }



            if (cageselect) {
                let index1 = cageselect.indexOf('#');
                if(index1 > 0 ) {
                    console.log('cageselect', index1);
                    currow.cage = cageselect.substring(index1 + 1);
                }else{
                    currow.ward=cageselect;
                }
            }
            if (satelliteSelect) {
                let index1 = satelliteSelect.indexOf('#');
                if(index1 > 0 ) {
                    console.log('satselect', index1);
                    currow.satellite = satelliteSelect.substring(index1 + 1);
                }else{
                    currow.satellite=satelliteSelect;
                }
            }

            //setRow(prop);

            if ( currow.inqty && /^\d+$/.test(currow.inqty) ) {
                console.log('###adding');

                if (currow.qty) {
                    let a = Number(currow.qty);
                    let b = Number(currow.inqty);
                    let c = b;
                    currow.qty = String(c);
                }
                if (!currow.qty) {
                    currow.qty = currow.inqty;
                }
                if (currow.saved && currow.saved === 1) {
                    currow.saved = null;
                }


                let idx;
                for (const r1 of rows) {
                    if (r1.rowindexer === currow.rowindexer) {
                        idx = rows.indexOf(r1);
                    }
                }

                console.log('###idx', idx);

                currow.added = 1;

                arrrowsforsave[idx] = currow;
                setRows([...arrrowsforsave]);
                //setRow(currow);
            }


        }catch( err ) {
            console.log(err) ;
        }
    }
    const handleqtyfield = (prop) => (val) => {
        //console.log('Table Says QTY: ', row );
        console.log('##From RECEIVE.js Table Says val.target.value: ', val.target.value);
        console.log('##prop: ', prop);
        try {
            prop.inqty = Number(val.target.value);
        } catch (err) {
            console.log(err)
        }
        setRow(prop);
    }



    function createData(description, vendorcatalognumber, manufacturernumber ) {
        return { description, vendorcatalognumber, manufacturernumber  };
    }



    return (
        <div  className="Receive" >

            <main className="content" id="MAIN_CONTENT">

                <div className="inputs" >
                    <Select className="siteselect"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={site}
                            onChange={handleChangeHospital  }
                    >{hospitals}
                    </Select>

                    <Select className="selectsatellite"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={satelliteSelect}
                            onChange={handleChangeSatellite}
                    >{satellites}


                    </Select>

                    <Select className="cageselect"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={cageselect}
                            onChange={handleChangecage}
                    >
                        {cages}
                    </Select>



                </div>

                <div className="inputcomponents">
                    <TextField
                        onKeyPress={(ev) => {
                            console.log(`####Pressed keyCode ${ev.key} `);
                            if (ev.key === 'Enter') {
                                // Do code here
                                //ev.preventDefault();
                                search();
                                console.log('ENTER');
                            }
                        }}

                        className="textin"
                               id="standard-multiline-flexible"
                               label="Enter VENCAT or DESCRIPTION"
                               rowsMax="1"
                               value={searchtext}
                               onChange={handleChange2}

                    />



                    <button className="searchbutton" variant="contained" color="primary"  onClick={ async () => {

                        if( !hospitalcode) {
                            alert('select a hospital') ;
                            return;
                        }

                        if(  cageselect  && cages.length > 1 &&  cageselect === '---') {
                            alert('select a cage') ;
                            return;
                            //
                        }

                        //alert('user', user );
                        const r = window.confirm("This will clear all your work - are you SURE?");

                        if( r ){
                            //sign Out logic
                            setIsSaving(true);

                        }else{
                            console.log('returning');
                            setIsSaving(false);
                            return ;
                        }


                        console.log('SEARCHBUTTON');
                        search();

                        //getppeitembydescription();

                    }} >
                        REFRESH
                    </button>
                </div>
                <div className="audittables">

                    <TableProt  tableAddButtonClick={ tableAddButtonClick }  handleqtyfield={handleqtyfield}  rows={rows}   />

                    <h1>ADJUST</h1>
                    {
                        rowsforsave.length > 0 &&
                        <TableForsave   handleButtonClickRemove={handleButtonClickRemove}  rows={rowsforsave}/>
                    }




                </div>
                {
                    !isSaving &&
                    <button className="searchbutton" variant="contained" color="primary" onClick={async () => {

                        setIsSaving(true);
                        for (const r1 of rows) {

                            if ( r1.added && !r1.saved) {
                                r1.user = user.attributes.email;
                                console.log('@@@@@@****###user', r1.user);
                                console.log('r1', r1);
                                await saveledgerentry(r1);
                                console.log('###r1', r1);
                                //getledgerentry(r1);
                                //await updateinsertinventorylevel(r1);
                            }
                        }
                        console.log('100% done');
                        //setRowsforsave(rowsforsave.filter(item => item.saved === 1));
                        setIsSaving(false);
                    }}>
                        SAVE
                    </button>
                }

                {
                    isSaving&&
                        <CircularIndeterminate></CircularIndeterminate>
                }


            </main>

        </div>
    );


}
