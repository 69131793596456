import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import TableProt from "./TableProt";
import TableForsave from "./TableForsave";
import React, {useEffect, useState} from "react";
import {API, Auth} from "aws-amplify";
import MenuItem from "@material-ui/core/MenuItem";
import TableReports from "./TableReports";
import TableConsumption from "./TableConsumption";
import RMaterialUIPickers from "./RMaterialUIPickers";
import Button from "@material-ui/core/Button";
import {CSVLink} from "react-csv";

import moment from "moment";
import CircularIndeterminate from "./CircularIndeterminate";
import TableGovtreport from "./TableGovtreport";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";


export default function Reports( props ) {

    const [site, setSite] = useState('---');
    const [selectedDate, setSelectedDate] = useState(  );
    const [hospitalcode, setHospitalcode] = useState('') ;
    const [user, setUser] = useState('');
    const[satellites, setSatellites] = useState([]);
    const[cages, setCages] = useState([]) ;
    const[wards, setWards] = useState([]);
    const [ parentcode, setParentcode] = useState('');
    const[hospitals, setHospitals] = useState([]);
    const [satelliteSelect, setSatelliteSelect] = useState('---') ;
    const [cageselect, setCageselect] = useState('---');
    const [rows, setRows] = useState([]);
    const [myMap, setMyMap] = useState(new Map());
    const [consumedMap, setConsumedMap] = useState( new Map() );
    const [totalRowMap, setTotalRowMap] = useState(new Map() );

    const [todayMap, setTodayMap] = useState( new Map() );
    const [rowsMap, setRowsmap ] = useState( new Map() );

    const [isProcessing, setIsProcessing ] = useState(false);

    const updateTotalRowMap = (k,v) => {
        setTotalRowMap( new Map( totalRowMap.set(k,v) ) );
    }

    const updateConsumedMap = (k,v) => {
        setConsumedMap( new Map( consumedMap.set(k,v) ) );
    }

    const updateMap = (k,v) => {
        setMyMap( new Map( myMap.set(k,v) ) );
    }

    const updateTodayMap = (k,v) => {
        setTodayMap( new Map( todayMap.set(k,v)));
    }

    const updateRowsMap = (k,v) => {
        setRowsmap( new Map( rowsMap.set(k,v)));
    }


    const [csvData, setCsvdata] = useState( [] );
    const [sevendaytable, setSevendaytable] = useState( false ) ;
    const [govttable, setGovttable] = useState(false);
    const [ rowsToday, setRowsToday ] = useState([]) ;


    useEffect(() => {
        //getHospcode();
        getParentcodefromCognito();


    }, [hospitalcode] );


    async function getHospcode() {
        console.log('getHospcode');
        const user = await Auth.currentAuthenticatedUser();
        let authinfo = await Auth.currentUserInfo();
        const favoriteFlavor = authinfo.attributes['custom:healthsystem'] ;
        setHospitalcode( favoriteFlavor );
    }

    async function getParentcodefromCognito() {
        console.log('getHospcode');
        const user1 = await Auth.currentAuthenticatedUser();
        setUser(user1);
        console.log('###user1', user1 );
        console.log('USER:', user);
        console.log('###user email', user.email );
        let authinfo = await Auth.currentUserInfo();


        const favoriteFlavor = authinfo.attributes['custom:healthsystem'] ;
        //setHospitalcode( favoriteFlavor );
        setParentcode(favoriteFlavor);
        console.log('parentcode from cognito', favoriteFlavor );
        await getlocationinfo(favoriteFlavor);
    }


    async function getlocationinfo(pccode) {

        console.log('##1- getlocationinfo-parentcode', pccode );

        let apiName = 'saveledgerentry';
        let path = '/getlocinfo';

        const myInit = { // OPTIONAL
            body: {
                pccode: pccode,
            },
            headers: {
                //'Content-Type' : 'application/json',
                // Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        };

        console.log('2- getlocationinfo with parentcode', parentcode );
        //console.log(API.endpoint(apiName));

        let resp1 = await API.post(apiName, path, myInit );
        console.log(resp1);
        //setRows( [...resp1.body.Items] ) ;




        let optionItemsCage = resp1.body.Items.filter( function (e) {
            return e.pk2.startsWith('CAGE#');
        }).map( (e2) =>
            <MenuItem key={e2.pk2} value={e2.pk2}>{e2.friendlyname}</MenuItem>
        );
        optionItemsCage.push  ( <MenuItem key='---' value='---'>SELECT CAGE</MenuItem>)
        setCages(optionItemsCage);

        //wards
        let optionItemsWard = resp1.body.Items.filter( function (e) {
            return e.pk2.startsWith('WARD#');
        }).map( (e2) =>
            <MenuItem key={e2.pk2} value={e2.pk2}>{e2.friendlyname}</MenuItem>
        );
        optionItemsWard.push  ( <MenuItem key='---' value='---'>SELECT WARD</MenuItem>)
        setWards(optionItemsWard);

        //hosps
        let optionItemsHospital = resp1.body.Items.filter( function (e) {
            return e.pk2.startsWith('HOSP#');
        }).map( (e2) =>
            <MenuItem key={e2.pk2} value={e2.pk2}>{e2.friendlyname}</MenuItem>
        );
        optionItemsHospital.push  ( <MenuItem key='---' value='---'>SELECT HOSP</MenuItem>)
        setHospitals(optionItemsHospital);

        let optionItemsSat = resp1.body.Items.filter( function (e) {
            return e.pk2.startsWith('SAT#');
        }).map( (e2) =>
            <MenuItem key={e2.pk2} value={e2.pk2}>{e2.friendlyname}</MenuItem>
        );
        optionItemsSat.push ( <MenuItem key='---' value='---'>SELECT SAT</MenuItem>)
        setSatellites( optionItemsSat ) ;

    }

    function getQueryDateNowString() {


        let date1  ; //= new Date();
        if(selectedDate) {
            console.log('### SELECTED DATE##### -', selectedDate);
            date1 = new Date( selectedDate ) ;
            date1.setHours(23,59,59,0);
            //let date2 = new Date( selectedDate );
            //date1.setDate( date2.getDate()-1  );
            console.log('### DATE CHANGED ##### -', date1 );
        }else{
            date1 = new Date();
            date1.setHours(23,59,59,0);
            //date1.setDate( (new Date()).getDate()-1 );
        }

        console.log('*** date1-QDS', date1);

        console.log('*** date1', date1);

        let year = date1.getUTCFullYear() ;
        //let month =  date1.getUTCMonth() +1 ;
        let month = ( date1.getUTCMonth() +1 ).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
       // let day = date1.getUTCDate() ;

        let day = (date1.getUTCDate() ).toLocaleString('en-US',{minimumIntegerDigits: 2, useGrouping:false})

        let hour = ( date1.getUTCHours()  ).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        //let datestring = month + ":" + day +":" + year  ;
        let datestring = year + '-' + month + '-' + day + 'T' + hour;

        console.log("###ZULU conversion", datestring );

        return datestring;
        //
        //let pk1 = pk1orig.replace(/INVLEVELCAGE/i, 'INVLEVELCAGEARCHIVE') + 'DATE#' + datestring;
        // let pk1 = 'INVLEVELCAGEARCHIVE#PARENTCODE#' + parentcode + 'HOSPITALCODE#' + hospitalcode +
        //     'CAGE#';



    }

    function getQueryDateTodayStartString() {


        let date1  ; //= new Date();
        if(selectedDate) {
            console.log('### SELECTED DATE##### -', selectedDate);
            date1 = new Date( selectedDate ) ;
            date1.setHours(0,0,0,0);

            //let date2 = new Date( selectedDate );
            //date2.setMinutes(0);
            //date2.setHours(0,0,0,0)
            //date1.setDate( date2 );
            //console.log('###  getQueryDateTodayStartString - ##### -', date1 );
        }else{
            date1 = new Date();
            //date1.setDate( (new Date()
            // ).getDate()-1 );
            date1.setHours(0,0,0,0);
            //console.log('###  getQueryDateTodayStartString - ##### -', date1 );
        }

        console.log('*** getQueryDateTodayStartString date1-QDS', date1);

        let year = date1.getUTCFullYear() ;
        //let month =  date1.getUTCMonth() +1 ;
        let month = ( date1.getUTCMonth() +1 ).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        //let day = date1.getUTCDate() ;
        let day = (date1.getUTCDate() ).toLocaleString('en-US',{minimumIntegerDigits: 2, useGrouping:false})

        let hour = ( date1.getUTCHours()  ).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        //let datestring = month + ":" + day +":" + year  ;
        let datestring = year + '-' + month + '-' + day + 'T' + hour;



        console.log("###ZULU conversion", datestring );

        return datestring;
        //
        //let pk1 = pk1orig.replace(/INVLEVELCAGE/i, 'INVLEVELCAGEARCHIVE') + 'DATE#' + datestring;
        // let pk1 = 'INVLEVELCAGEARCHIVE#PARENTCODE#' + parentcode + 'HOSPITALCODE#' + hospitalcode +
        //     'CAGE#';



    }

    function getDateString() {

        // INVLEVELCAGEARCHIVE#PARENTCODE#C1008HOSPITALCODE#C1008CAGE#CAGE01DATE#7:21:2020
        //let date1 = new Date();
        let date1;
        if(selectedDate) {
             date1 = selectedDate;
        }else{
            date1 = new Date();
        }


        let year = date1.getUTCFullYear() ;
        let month =  date1.getUTCMonth() +1 ;
        let day = date1.getUTCDate() ;
        let datestring = month + ":" + day +":" + year  ;


        return datestring;
        //
        //let pk1 = pk1orig.replace(/INVLEVELCAGE/i, 'INVLEVELCAGEARCHIVE') + 'DATE#' + datestring;
        // let pk1 = 'INVLEVELCAGEARCHIVE#PARENTCODE#' + parentcode + 'HOSPITALCODE#' + hospitalcode +
        //     'CAGE#';



    }

    function isdateToday(datestring) {

        let date1 = new Date();

        let year = date1.getUTCFullYear() ;
        let month =  date1.getUTCMonth() +1 ;
        let day = date1.getUTCDate() ;
        let datestringtoday = month + ":" + day +":" + year  ;

        if(datestringtoday === datestring){
            console.log('date string is today')
            return true;
        }
        else{
            console.log('date string is NOT today');
            return false;
        }

    }

    async function loadinvlevelsnodisplay (){

        setSevendaytable(false);
        setGovttable(true);

        console.log('loadinvlevels - selecteddate', selectedDate ) ;
        console.log('### cage select', cageselect );

        let apiName = 'saveledgerentry';
        let path = '/searchledgerbydate';

        let datestring = getDateString();

        isdateToday(datestring);


       // setRows([]);
        updateMap( myMap.clear() );

        let counter = 0;

        for (const r of cages ) {

            console.log('### R.KEY ', r.key );


            if( ( cageselect === '---' ) && ( r.key !='---')   || (cageselect === r.key) ) {
                console.log('r', r.key);

                console.log('### LOOPING...');

                let myInit;

                if( selectedDate && !isdateToday(datestring)) {

                    myInit = {
                        'queryStringParameters': {
                            pk1: 'INVLEVELCAGEARCHIVE#PARENTCODE#' + hospitalcode + 'HOSPITALCODE#' + hospitalcode + r.key + 'DATE#' + datestring,
                            pk2: '',
                        },
                    };
                }
                else{
                    //                console.log('INVLEVELCAGE#PARENTCODE#' + hospitalcode + 'HOSPITALCODE#' + hospitalcode + r.key);
                    myInit = {
                        'queryStringParameters': {
                            pk1: 'INVLEVELCAGE#PARENTCODE#' + hospitalcode + 'HOSPITALCODE#' + hospitalcode + r.key,
                            pk2: '',
                        },
                    };

                }

                console.log('searchtext', myInit );

                let resp1 = await API.get(apiName, path, myInit);

                console.log('@@#####response', resp1.body.Items ) ;

                if(resp1.body.Items.length > 0 ) {
                    //setRows(rows.concat([...resp1.body.Items]));

                    let rowstemp = [...resp1.body.Items] ;


                    let dataarray = [];
                    let arraycounter = 0;
                    //let fileheader = ['VENDORCATALOGNUMBER','QOH', 'DESCRIPTION' ] ;
                    //dataarray[0] = fileheader;
                    for (const r1 of rowstemp) {
                        let dataline = [];
                        r1.include = 1 ;
                        r1.rowindexer = counter++;
                        r1.pk2 = '---' ;

                        dataline[0]= r1.vendorcatalognumber;
                        dataline[1] = r1.quantity;
                        dataline[2] = r1.description.replace("\"",'');
                        dataline[3] = r1.unitofpurchase ;

                        dataarray[arraycounter] = dataline ;
                        //setCsvdata(csvData.concat(dataline) );
                        arraycounter++;
                        console.log('###DATALINE', dataline );
                        console.log('$$$DATARRAY', dataarray);
                        //llUsers = allUsers.concat ( [...rest.Users]  ) ;

                        //setCsvdata( dataline );

                        updateMap(r1.vendorcatalognumber, myMap.get(r1.vendorcatalognumber) ? (myMap.get(r1.vendorcatalognumber) + r1.quantity )  : r1.quantity );

                    }

                    //setRows(rows => rows.concat( rowstemp  ));
                    console.log("###DATA ARRAY LENGTH", dataarray.length);
                    //setCsvdata( csvData => csvData.concat( dataarray ) );
                }

            }

        }

        //totals

        console.log('###myMap', myMap) ;



    }

    async function loadinvlevels (){

        setSevendaytable(false);
        setGovttable(false);
        console.log('loadinvlevels - selecteddate', selectedDate ) ;
        console.log('### cage select', cageselect );

        let apiName = 'saveledgerentry';
        let path = '/searchledgerbydate';

        let datestring = getDateString();

        isdateToday(datestring);


        setRows([]);
        updateMap( myMap.clear() );

        let counter = 0;

        for (const r of cages ) {

            console.log('### R.KEY ', r.key );


            if( ( cageselect === '---' ) && ( r.key !='---')   || (cageselect === r.key) ) {
                console.log('r', r.key);

                console.log('### LOOPING...');

                let myInit;

                if( selectedDate && !isdateToday(datestring)) {

                     myInit = {
                        'queryStringParameters': {
                            pk1: 'INVLEVELCAGEARCHIVE#PARENTCODE#' + hospitalcode + 'HOSPITALCODE#' + hospitalcode + r.key + 'DATE#' + datestring,
                            pk2: '',
                        },
                    };
                }
                else{
                    //                console.log('INVLEVELCAGE#PARENTCODE#' + hospitalcode + 'HOSPITALCODE#' + hospitalcode + r.key);
                    myInit = {
                        'queryStringParameters': {
                            pk1: 'INVLEVELCAGE#PARENTCODE#' + hospitalcode + 'HOSPITALCODE#' + hospitalcode + r.key,
                            pk2: '',
                        },
                    };

                }

                console.log('searchtext', myInit );

                let resp1 = await API.get(apiName, path, myInit);

                console.log('@@#####response', resp1.body.Items ) ;

                if(resp1.body.Items.length > 0 ) {
                    //setRows(rows.concat([...resp1.body.Items]));

                    let rowstemp = [...resp1.body.Items] ;


                    let dataarray = [];
                    let arraycounter = 0;
                    //let fileheader = ['VENDORCATALOGNUMBER','QOH', 'DESCRIPTION' ] ;
                    //dataarray[0] = fileheader;
                    for (const r1 of rowstemp) {
                        let dataline = [];
                        r1.include = 1 ;
                        r1.rowindexer = counter++;
                        r1.pk2 = '---' ;

                        dataline[0]= r1.vendorcatalognumber;
                        dataline[1] = r1.quantity;
                        dataline[2] = r1.description.replace("\"",'');
                        dataline[3] = r1.unitofpurchase ;

                        dataarray[arraycounter] = dataline ;
                        //setCsvdata(csvData.concat(dataline) );
                        arraycounter++;
                        console.log('###DATALINE', dataline );
                        console.log('$$$DATARRAY', dataarray);
                        //llUsers = allUsers.concat ( [...rest.Users]  ) ;

                        //setCsvdata( dataline );

                        updateMap(r1.vendorcatalognumber, myMap.get(r1.vendorcatalognumber) ? (myMap.get(r1.vendorcatalognumber) + r1.quantity )  : r1.quantity );

                    }

                    setRows(rows => rows.concat( rowstemp  ));
                    console.log("###DATA ARRAY LENGTH", dataarray.length);
                    setCsvdata( csvData => csvData.concat( dataarray ) );
                }

            }

        }

        //totals

        console.log('###myMap', myMap) ;



    }

    async function loadtodayinvlevels (){

        setSevendaytable(false);
        console.log('loadinvlevels - selecteddate', selectedDate ) ;
        console.log('### cage select', cageselect );

        let apiName = 'saveledgerentry';
        let path = '/searchledgerbydate';

        let datestring = getDateString();

        isdateToday(datestring);


        //setRows([]);
        //updateMap( myMap.clear() );

        let counter = 0;

        for (const r of cages ) {

            console.log('### R.KEY ', r.key );


            if( ( cageselect === '---' ) && ( r.key !='---')   || (cageselect === r.key) ) {
                console.log('r', r.key);

                console.log('### LOOPING...');

                let myInit;

                if(true){
                    //                console.log('INVLEVELCAGE#PARENTCODE#' + hospitalcode + 'HOSPITALCODE#' + hospitalcode + r.key);
                    myInit = {
                        'queryStringParameters': {
                            pk1: 'INVLEVELCAGE#PARENTCODE#' + hospitalcode + 'HOSPITALCODE#' + hospitalcode + r.key,
                            pk2: '',
                        },
                    };

                }

                console.log('searchtext', myInit );

                let resp1 = await API.get(apiName, path, myInit);

                console.log('@@#####response', resp1.body.Items ) ;

                if(resp1.body.Items.length > 0 ) {
                    //setRows(rows.concat([...resp1.body.Items]));

                    let rowstemp = [...resp1.body.Items] ;


                    let dataarray = [];
                    let arraycounter = 0;
                    //let fileheader = ['VENDORCATALOGNUMBER','QOH', 'DESCRIPTION' ] ;
                    //dataarray[0] = fileheader;
                    for (const r1 of rowstemp) {
                        let dataline = [];
                        r1.include = 1 ;
                        r1.rowindexer = counter++;
                        r1.pk2 = '---' ;

                        dataline[0]= r1.vendorcatalognumber;
                        dataline[1] = r1.quantity;
                        dataline[2] = r1.description.replace("\"",'');
                        dataline[3] = r1.unitofpurchase ;

                        dataarray[arraycounter] = dataline ;
                        //setCsvdata(csvData.concat(dataline) );
                        arraycounter++;
                        console.log('###DATALINE', dataline );
                        console.log('$$$DATARRAY', dataarray);
                        //llUsers = allUsers.concat ( [...rest.Users]  ) ;

                        //setCsvdata( dataline );

                        updateTodayMap(r1.vendorcatalognumber, todayMap.get(r1.vendorcatalognumber) ? (todayMap.get(r1.vendorcatalognumber) + r1.quantity )  : r1.quantity );

                    }

                    setRowsToday(rows => rows.concat( rowstemp  ));
                    console.log("###DATA ARRAY LENGTH", dataarray.length);
                    //setCsvdata( csvData => csvData.concat( dataarray ) );
                }

            }

        }

        //totals

        //console.log('###myMap', myMap) ;



    }

    async function getyesterdaydaterange(){

        let t1 = moment();
        let y1 = t1.subtract(1, 'day',  );


        let d1 = y1.toDate();

        let year = d1.getUTCFullYear() ;
        //let month =  date1.getUTCMonth() +1 ;
        let month = ( d1.getUTCMonth() +1 ).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        //let day = date1.getUTCDate() ;
        let day = (d1.getUTCDate() ).toLocaleString('en-US',{minimumIntegerDigits: 2, useGrouping:false})

        let hour = ( d1.getUTCHours()  ).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        //let datestring = month + ":" + day +":" + year  ;
        let datestring = year + '-' + month + '-' + day + 'T' + hour;

        console.log("###ZULU conversion getyesterdaydaterange", datestring );

        return datestring;

    }

    async function getsevendaydaterange(){

        let t1 = moment();

        let y1 = t1.subtract(8, 'day',  );

        let d1 = y1.toDate();

        let year = d1.getUTCFullYear() ;
        //let month =  date1.getUTCMonth() +1 ;
        let month = ( d1.getUTCMonth() +1 ).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        //let day = date1.getUTCDate() ;
        let day = (d1.getUTCDate() ).toLocaleString('en-US',{minimumIntegerDigits: 2, useGrouping:false})

        let hour = ( d1.getUTCHours()  ).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        //let datestring = month + ":" + day +":" + year  ;
        let datestring = year + '-' + month + '-' + day + 'T' + hour;

        console.log("###ZULU conversion - getsevendaydaterange ", datestring );

        return datestring;

    }



    async function loadinvlevelssevenday () {

        setSevendaytable(true) ;
        setGovttable(false);

        let yesterday = await getyesterdaydaterange();
        let sevendaysago = await getsevendaydaterange() ;

        setIsProcessing(true);
        //await showdates();

        console.log('loadinvlevelssevenday ');
        //console.log('### cage select', cageselect );

        let apiName = 'saveledgerentry';
        let path = '/searchledgerbydate';

        setRows([]);
        updateMap(myMap.clear());

        let counter = 0;

            //console.log('~~~~~d of arraysOfDays', d );

            //for (const r of cages) {

            //console.log('### R.KEY ', r.key);


           // if ((cageselect === '---') && (r.key != '---') || (cageselect === r.key)) {
               // console.log('r', r.key);

                console.log('### LOOPING...');

                let myInit;

               // if (selectedDate) {

                    myInit = {
                        'queryStringParameters': {
                            pk1: 'INV#PARENTCODE#' + parentcode + 'HOSPITALCODE#' + hospitalcode,
                            pk2: sevendaysago,
                            pk3: yesterday
                        },
                    };
                //}

                console.log('searchtext', myInit);

                let resp1 = await API.get(apiName, path, myInit);

                console.log('@@#####response', resp1.body.Items);

                if (resp1.body.Items.length > 0) {
                    //setRows(rows.concat([...resp1.body.Items]));

                    let rowstemp = [...resp1.body.Items];


                    let dataarray = [];
                    let arraycounter = 0;
                    //let fileheader = ['VENDORCATALOGNUMBER','QOH', 'DESCRIPTION' ] ;
                    //dataarray[0] = fileheader;
                    for (const r1 of rowstemp) {
                        let dataline = [];
                        r1.include = 1;
                        r1.rowindexer = counter++;
                        r1.pk2 = '---';

                        dataline[0] = r1.vendorcatalognumber;
                        dataline[1] = r1.quantity;
                        dataline[2] = r1.description.replace("\"", '');
                        dataline[3] = r1.unitofpurchase;

                        dataarray[arraycounter] = dataline;
                        //setCsvdata(csvData.concat(dataline) );
                        arraycounter++;
                        //console.log('###DATALINE', dataline);
                        //console.log('$$$DATARRAY', dataarray);
                        //llUsers = allUsers.concat ( [...rest.Users]  ) ;

                        //setCsvdata( dataline );

                        updateMap(r1.vendorcatalognumber, myMap.get(r1.vendorcatalognumber) ? ( (myMap.get(r1.vendorcatalognumber) + r1.quantity) ) : r1.quantity);

                    }

                    let values = myMap.values() ;
                    let keys = myMap.keys() ;

                    console.log('###### - LIST #####', values );
                    console.log('###### - KEYS #####', keys );

                   for  ( const r1 of keys ){
                       updateMap( r1, myMap.get( r1)/7 );
                    }

                   let uniquemap = new Map();

                   for ( const r2 of rowstemp) {
                       if(r2) {
                           uniquemap.set(r2.vendorcatalognumber, r2);
                       }
                    }

                    //console.log('ROWSTEMP VALUES', rowstemp );
                   //onsole.log('UNIQUEMAP VALUES', Array.from( uniquemap.values() ) );

                    //setRows(rows => rows.concat(Array.from( uniquemap.values )) );

                    //setRows( Array.from( uniquemap.values ) );

                    // let array1 = []
                    // for ( const r3 of Array.from(uniquemap.values ) ) {
                    //     array1.concat(r3);
                    // }

                    console.log( '@@@uniquemap size', uniquemap.size );
                    let newarray = await copyarrayfrommap(uniquemap );

                    console.log( '$$$$$rows size', newarray.length );
                     setRows( rows => rows.concat( newarray ) );

                    console.log("###DATA ARRAY LENGTH", dataarray.length);
                    //setCsvdata(csvData => csvData.concat(dataarray));

                }


        //totals

        setIsProcessing(false);
        //console.log('###myMap', myMap) ;



    }

    async function copyarrayfrommap(uniquemap) {
        console.log("### COPY ARRAY",  uniquemap.size ) ;

        console.log("### COPY ARRAY", Array.from( uniquemap.values()  ));
        let array1 = [ ...uniquemap.values() ];
        // let idx = 0;
        // for ( const r3 of Array.from(uniquemap.values() ) ) {
        //     array1[idx] = [...r3] ;
        //     console.log('!!!array1[idx', array1[idx] ) ;
        //     idx++;
        // }

        return array1;
    }


//INVLEVELCAGEARCHIVE#PARENTCODE#C1008HOSPITALCODE#C1008CAGE#CAGE01DATE#7:21:2020
    const handleDatechange = (prop) => (val) =>  {
        console.log('handleDatechange - prop: ', prop );
        console.log('handleDatechange - val: ', val );
        setSelectedDate(val);
    }

    const handleChangeHospital = async ( val1  ) => {
        console.log('dropdown change with', val1 );
        console.log('dropdown change with', val1.target.value );
        setSite( val1.target.value );
        console.log('$$$$ 1 hospitalcode ', val1.target.value );
        let index1 = val1.target.value.indexOf('#');
        console.log('$$$$hospitalcode index', index1 );
        let hc = val1.target.value.substring(index1 +1 ) ;
        console.log('####hc', hc );
        setHospitalcode( hc  ) ;
        //console.log('$$$$hospitalcode', hospitalcode   ) ;
    };


    const handleChangeSatellite = (val3) => {
        console.log('###change###');
        setSatelliteSelect(val3.target.value);
        //setSelectsatellite(val3.target.value );
    };

    const handleChangecage = ( val ) => {
        setCageselect( val.target.value );
    }

    async function loadallactivity() {

        setSevendaytable(false);
        let counter = 0;

        console.log('loadinvlevels - selecteddate', selectedDate ) ;
        console.log('### cage select', cageselect );

        let apiName = 'saveledgerentry';
        let path = '/searchledgerbydate';

        let datestring = getQueryDateTodayStartString() ;
        let datenowstring = getQueryDateNowString() ;

        setRows([]);
        updateMap( myMap.clear() );


        let myInit = {
            'queryStringParameters': {
                pk1: 'INV#PARENTCODE#' + parentcode + 'HOSPITALCODE#' + hospitalcode,
                pk2: datestring,
                pk3: datenowstring,
            },
        };

        let resp1 = await API.get(apiName, path, myInit);

        console.log('@@#####response', resp1.body.Items ) ;

        setCsvdata([]);
        if(resp1.body.Items.length > 0 ) {
            //setRows(rows.concat([...resp1.body.Items]));

            let rowstemp = [...resp1.body.Items] ;

            let dataarray =[];
            let arraycounter = 0;
            for (const r1 of rowstemp) {

                let dataline = [];

                    r1.include = 1;
                    r1.rowindexer = counter++;
                    let locdate = new Date(r1.pk2);
                    console.log('#### ', locdate );
                    r1.pk2 = locdate.toString();

                dataline[0]= r1.vendorcatalognumber;
                dataline[1] = r1.quantity;
                dataline[2] = r1.description.replace("\"",'');

                dataarray[arraycounter] = dataline ;
                arraycounter++;

                    //updateMap(r1.vendorcatalognumber, myMap.get(r1.vendorcatalognumber) ? (myMap.get(r1.vendorcatalognumber) + r1.quantity) : r1.quantity);

            }

            setRows(rows => rows.concat( rowstemp  )   );
            setCsvdata( csvData => csvData.concat( dataarray ) );

        }


    }
    async function loadtwentyfourhourconsumption() {

        setSevendaytable(false);
        setCsvdata([]);
        let counter = 0;

        console.log('loadinvlevels - selecteddate', selectedDate ) ;
        console.log('### cage select', cageselect );

        let apiName = 'saveledgerentry';
        let path = '/searchledgerbydate';

        //this is now - 24 hours
       //let datestring = getQueryDateString();
        let datestring = getQueryDateTodayStartString() ;
        let datenowstring = getQueryDateNowString() ;

        setRows([]);
        updateMap( myMap.clear() );


        let myInit = {
                'queryStringParameters': {
                    pk1: 'INV#PARENTCODE#' + parentcode + 'HOSPITALCODE#' + hospitalcode,
                    pk2: datestring,
                    pk3: datenowstring,
                },
            };

        let resp1 = await API.get(apiName, path, myInit);

        console.log('@@#####response', resp1.body.Items ) ;

        if(resp1.body.Items.length > 0 ) {
            //setRows(rows.concat([...resp1.body.Items]));

            let rowstemp = [...resp1.body.Items] ;


            let dataarray = [];
            let arraycounter = 0;
            for (const r1 of rowstemp) {
                let dataline = [] ;

                if( r1.itemstatus ==='PICK') {

                    let dataline = [];
                    r1.include = 1;
                    r1.rowindexer = counter++;
                    let locdate = new Date(r1.pk2);
                    console.log('#### ', locdate );
                    r1.pk2 = locdate.toString();

                    dataline[0]= r1.vendorcatalognumber;
                    dataline[1] = r1.quantity;
                    dataline[2] = r1.description.replace("\"",'');

                    dataarray[arraycounter] = dataline ;
                    arraycounter++;

                    updateMap(r1.vendorcatalognumber, myMap.get(r1.vendorcatalognumber) ? (myMap.get(r1.vendorcatalognumber) + r1.quantity) : r1.quantity);

                }else{
                    console.log('#####NOT PICK') ;
                }

            }

            setRows(rows => rows.concat( rowstemp  ).filter( row => row.itemstatus === 'PICK')   );
            setCsvdata( csvData => csvData.concat( dataarray ) );
        }


    }

    async function loadtwentyfourhourconsumptionnoqty() {

        setSevendaytable(false);
        setGovttable(true);
        setCsvdata([]);
        let counter = 0;

        console.log('loadtwentyfourhourconsumptionnoqty - selecteddate', selectedDate ) ;
        console.log('### cage select', cageselect );

        let apiName = 'saveledgerentry';
        let path = '/searchledgerbydate';

        //this is now - 24 hours
        //let datestring = getQueryDateString();
        let datestring = getQueryDateTodayStartString() ;
        let datenowstring = getQueryDateNowString() ;

        setRows([]);
        //updateMap( myMap.clear() );
        updateConsumedMap( consumedMap.clear() );



        let myInit = {
            'queryStringParameters': {
                pk1: 'INV#PARENTCODE#' + parentcode + 'HOSPITALCODE#' + hospitalcode,
                pk2: datestring,
                pk3: datenowstring,
            },
        };

        let resp1 = await API.get(apiName, path, myInit);

        console.log('@@#####response', resp1.body.Items ) ;

        if(resp1.body.Items.length > 0 ) {
            //setRows(rows.concat([...resp1.body.Items]));

            let rowstemp = [...resp1.body.Items] ;


            let dataarray = [];
            let arraycounter = 0;
            for (const r1 of rowstemp) {
                let dataline = [] ;

                if( r1.itemstatus ==='PICK') {

                    let dataline = [];
                    r1.include = 1;
                    r1.rowindexer = counter++;
                    let locdate = new Date(r1.pk2);
                    //console.log('#### ', locdate );
                    r1.pk2 = locdate.toString();

                    dataline[0]= r1.vendorcatalognumber;
                    dataline[1] = r1.quantity;
                    dataline[2] = r1.description.replace("\"",'');

                    dataarray[arraycounter] = dataline ;
                    arraycounter++;


                    //console.log('$$$$updateing wth, ', r1.vendorcatalognumber );
                    //console.log('$$$updateing wth, ', r1.quantity );
                     updateTotalRowMap( r1.vendorcatalognumber, r1 ) ;
                     updateConsumedMap(r1.vendorcatalognumber, consumedMap.get(r1.vendorcatalognumber) ? (consumedMap.get(r1.vendorcatalognumber) + r1.quantity) : r1.quantity);

                }else{
                    console.log('#####NOT PICK' ) ;
                }
            }
            console.log('####CM SIZE', consumedMap.keys() );
            //setRows(rows => rows.concat( rowstemp  ).filter( row => row.itemstatus === 'PICK')   );
            setRows( Array.from( totalRowMap.values() ));
            await setgovtreportdownloaddata( Array.from( totalRowMap.values() ) );

        }

    }

    async function setgovtreportdownloaddata(rowsgvt) {


        let dataarray = [];

        // dataline[0]= r1.vendorcatalognumber;
        // dataline[1] = r1.quantity;
        // dataline[2] = r1.description.replace("\"",'');
        // dataline[3] = r1.unitofpurchase ;
        //
        // dataarray[arraycounter] = dataline ;
        //setCsvdata(csvData.concat(dataline) );

        //let csvlist = [];
        let arraycounter = 0;

        console.log('##dataarray ROWS', rowsgvt );

        for ( const r1 of rowsgvt ) {
            let dataline = [] ;
            console.log('####R1', r1 );
            dataline[0]= r1.vendorcatalognumber;
            dataline[1] = myMap.get( r1.vendorcatalognumber );
            dataline[2] = consumedMap.get( r1.vendorcatalognumber );
            dataline[3] = r1.description.replace("\"",'');
            dataline[4] = rowsMap.get(r1.vendorcatalognumber);
            dataline[5] = r1.stocknumber;
            dataline[6] = r1.unitofpurchase;
            dataarray[arraycounter] = [...dataline];
            arraycounter++;
        }
        console.log('##dataarray gov report', dataarray );
        setCsvdata( csvData => csvData.concat( dataarray ) );

        // <TableCell align="left">{row.vendorcatalognumber }</TableCell>
        // <TableCell align="left">{ myMap.get (row.vendorcatalognumber) }</TableCell>
        // <TableCell align="left">{ consumedMap.get(row.vendorcatalognumber) }</TableCell>
        // <TableCell align="left">{row.description}</TableCell>
        // <TableCell align="left">{row.stocknumber}</TableCell>
        // <TableCell align="left">{row.unitofpurchase}</TableCell>

    }

    async function search() {
        let searchtext = '' ;
        console.log('1- getppeitem');
        console.log('2 - SEARCH TEXT', searchtext);


        let apiName = 'saveledgerentry';
        let path = '/getppeitem';

        const myInit = { // OPTIONAL
            body: {
                pk1: 'UNIQUEPRODUCT#PARENTCODE#'+ parentcode+ 'HOSPCODE#' + hospitalcode,
                sk1: searchtext,
            },
            headers: {
                //'Content-Type' : 'application/json',
                // Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        };

        console.log('2- getppeitem');
        //console.log(API.endpoint(apiName));

        let resp1 = await API.post(apiName, path, myInit );
        console.log(resp1);
        //const returnedrows = [...resp1.body.Items] ;

        let counter = 0;
        if( resp1.body.Items && resp1.body.Items.length >0 ){
            console.log('response with vencat search - done');

            let rowstemp = [...resp1.body.Items] ;

            for (const r1 of rowstemp) {

                r1.include = 1 ;
                r1.ward = '---';
                r1.rowindexer = counter++;
                updateRowsMap( r1.vendorcatalognumber, r1.typedescription );
                console.log( '^^^^VENCAT ' + r1.vendorcatalognumber  + ' ' +  '^^^^TD ' + r1.typedescription );

            }


            //setRowsmap( r1.vendorcatalognumber, r1 ) ;
            //setRows( rowstemp.sort((a, b) => a.description > b.description ? 1 : -1) ) ;
            //setRowsfordisplay( rowstemp.sort((a, b) => a.description > b.description ? 1 : -1) ) ;
        }

       //setSearchtext('');
        //setIsSaving(false);
    }

    return (
        <div  className="Reports" >

            <main className="content" id="MAIN_CONTENT">

                <div className="inputs" >
                    <Select className="siteselect"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={site}
                            onChange={handleChangeHospital  }
                    >{hospitals}
                    </Select>

                    <Select className="selectsatellite"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={satelliteSelect}
                            onChange={handleChangeSatellite}
                    >{satellites}


                    </Select>

                    <Select className="cageselect"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={cageselect}
                            onChange={handleChangecage}
                    >
                        {cages}
                    </Select>



                </div>

                <div className="pickercomponents">
                    <RMaterialUIPickers selectedDate={selectedDate} handleDatechange={handleDatechange} className="datepicker"></RMaterialUIPickers><button className="reportsearchbutton" onClick={ async () => {
                    console.log('QUERY QOH');
                    if(!hospitalcode){
                        alert('SELECT HOSPITAL');
                        return;
                    }
                    await loadinvlevels();


                }}>QUERY QOH</button>
                    <button className="reportsearchbutton" onClick={ async () => {
                        console.log('###### PAST 24HR CONSUMED');
                        if(!hospitalcode){
                            alert('SELECT HOSPITAL');
                            return;
                        }
                        await loadtwentyfourhourconsumption();


                    }}>CONSUMED</button>
                    <button className="reportsearchbutton" onClick={ async () => {
                        console.log('###### PAST 24HR CONSUMED');
                        if(!hospitalcode){
                            alert('SELECT HOSPITAL');
                            return;
                        }
                        await loadallactivity();


                    }}>ALL ACTIVITY</button>

                    { !isProcessing &&
                        <button className="reportsearchbutton" onClick={async () => {
                            console.log('###### PAST 24HR CONSUMED');

                            //await showdates();

                            //await getyesterdaydaterange() ;
                            //await getsevendaydaterange() );

                            setIsProcessing(true);
                            await loadtodayinvlevels();
                            await loadinvlevelssevenday();

                        }}>7 DAY AVE USAGE</button>
                    }

                    {
                        isProcessing &&
                        <CircularIndeterminate className="spinner"  ></CircularIndeterminate>
                    }


                    <button className="ministryreportbutton" onClick={async () => {
                        console.log('###### GOVT REPORT');

                        //await showdates();

                        //await getyesterdaydaterange() ;
                        //await getsevendaydaterange() );
                        //setGovttable(true);

                        await search();
                        await loadinvlevelsnodisplay();
                        await loadtwentyfourhourconsumptionnoqty();
                        //await setgovtreportdownloaddata();


                    }}>MNSTRY RPT</button>




                </div>



                <div>
                    { 1===0 &&
                        <ul>
                            {
                                [...myMap.keys()].map(k => (
                                    <li key={k}>VendorCat {k} {myMap.get(k)} </li>
                                ))
                            }
                        </ul>
                    }
                </div>

                <div className="audittables">

                    {
                        !govttable && !sevendaytable && <TableReports rows={rows} myMap={myMap}/>
                    }
                    {
                        !govttable && sevendaytable && <TableConsumption rows={rows} myMap={myMap} todayMap = {todayMap} />
                    }
                    {
                        !sevendaytable && govttable && <TableGovtreport rows={rows} myMap={myMap} consumedMap={consumedMap} rowsMap={rowsMap}/>
                    }

                </div>
                <div>
                    <CSVLink className="reportsearchlink" data={csvData}  filename={"ppe.csv"} >DOWNLOAD</CSVLink>
                </div>


            </main>

        </div>
    );


}
