import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Checkbox} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import green from "@material-ui/core/colors/green";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

// function createData(description, vendorcatalonumber, manufacturernumber ) {
//     return { description, vendorcatalonumber, manufacturernumber  };
// }
//
// const rows = [
//     createData('N95 M SMALL', '999-aa', '73773'),
//     createData('N95 M LRGE', '44593222', '33334'),
// ];

export default function


    TableProt( props ) {


    const {
        handleqtyfield,
        tableAddButtonClick,
        rows,
    } = props

    const classes = useStyles();

    let inqty ;
    let row;


    const handleqtyfieldloc = (prop) => (val) => {
        console.log('prop', prop);
        console.log('val', val.target.value );
        if ( val.target.value &&  /^\d+$/.test(val.target.value) ) {
            prop.inqty = val.target.value;
        }
        // prop.qty = prop.inqty;
    }

    // function handleqtyfieldloc( row ) {
    //    console.log(row);
    // }

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">SELECT</TableCell>

                        <TableCell align="left"> QTY </TableCell>

                        <TableCell align="left"> TOTAL QTY </TableCell>
                        <TableCell align="left">Description</TableCell>
                        <TableCell align="left">Vendor Cat</TableCell>
                        <TableCell align="left">Hospital Number</TableCell>
                        <TableCell align="left">Unit Of Issue</TableCell>
                        <TableCell align="left">Date</TableCell>
                        <TableCell align="left">Ward</TableCell>
                        <TableCell align="left">Added</TableCell>
                        <TableCell align="left">Saved</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.filter( r1 => r1.include ===1 ).map( (row) => (

                        <TableRow key={ row.rowindexer }>
                            <TableCell padding="checkbox">
                                <Button variant="contained" color="primary"
                                        //value={ row.rowindexer }
                                    onClick={ tableAddButtonClick(row) }
                                >Add</Button>
                            </TableCell>
                            <TableCell padding="checkbox">
                                    <TextField className="textin"
                                               id="standard-multiline-flexible"
                                               value={ inqty }
                                               onChange={ handleqtyfieldloc(row) }
                                    />
                            </TableCell>


                            <TableCell align="left">{ row.qty } </TableCell>
                            <TableCell align="left">{row.description}</TableCell>
                            <TableCell align="left">{row.vendorcatalognumber }</TableCell>

                            <TableCell align="left">{row.stocknumber}</TableCell>
                            <TableCell align="left">{row.unitofpurchase}</TableCell>
                            <TableCell align="left">{row.date1 }</TableCell>
                            <TableCell align="left">{ row.ward != -1 && row.ward !=0  ? row.ward: '' }</TableCell>
                            <TableCell align="left">
                                {
                                    row.added && <CheckIcon style={{ background: green[500] }} align="center"></CheckIcon>
                                }
                            </TableCell>
                            <TableCell align="left">

                                {
                                    row.saved && <CheckIcon style={{ background: green[500] }} align="center"></CheckIcon>
                                }

                            </TableCell>


                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
