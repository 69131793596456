import Amplify, { Auth, API } from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';

import './App.css';
import React, {Component, useEffect} from 'react';

import  {useState} from 'react';

import awsconfig from './aws-exports';
import '@aws-amplify/ui/dist/style.css';

import MenuIcon from "@material-ui/icons/Menu";

import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

import Toolbar from "@material-ui/core/Toolbar";

import { Button } from '@material-ui/core';

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";


import MenuItem from "@material-ui/core/MenuItem";
import Select from '@material-ui/core/Select';

import TextField from "@material-ui/core/TextField";

import Box from '@material-ui/core/Box';
import CountTable from "./components/CountTable.tsx";
import TableProt from "./components/TableProt";
import TableForsave from "./components/TableForsave";
import Divider from "@material-ui/core/Divider";
import {Label} from "@material-ui/icons";

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Receive from "./components/Receive";
import Pick from "./components/Pick" ;

import { useHistory } from "react-router-dom";
import Reports from "./components/Reports";
import Adjustments from "./components/Adjustments";
import Useradmin from "./components/Useradmin";

import packageJson from '../package.json';
import Cagetransfer from "./components/Cagetransfer";
import Countonly from "./components/Countonly";



Amplify.configure(awsconfig);

async function addToGroup() {
  let apiName = 'AdminQueries';
  let path = '/addUserToGroup';
  let myInit = {
    body: {
      "username" : "awesomeeditor",
      "groupname": "editors"
    },
    headers: {
      'Content-Type' : 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
    }
  }
  return await API.post(apiName, path, myInit);
}


let nextToken;

async function listEditors(limit){

  console.log('list editors');
  let apiName = 'AdminQueries';
  let path = '/listUsersInGroup';
  let myInit = {
    queryStringParameters: {
      "groupname": "editors",
      "limit": limit,
      "token": nextToken
    },
    headers: {
      'Content-Type' : 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
    }
  }
  const { NextToken, ...rest } =  await API.get(apiName, path, myInit);

  nextToken = NextToken;
  console.log(rest);
  return rest;
}




function App() {

  const [hospitalcode, setHospitalcode] = useState('') ;
  const[parentcode, setParentcode ] = useState('');

  const [site, setSite] = useState('---');
  const [satelliteSelect, setSatelliteSelect] = useState('---') ;
  const [cageselect, setCageselect] = useState('---');
  const [searchtext, setSearchtext] = useState('');
  const[wardselect, setWardselect] = useState('---');
  const[hospitalselect, setHospitalselect] = useState('---');

  const[rows, setRows] = useState([]);
  const[rowsforsave, setRowsforsave] = useState([]);
  const[row, setRow] = useState();

  const[satellites, setSatellites] = useState([]);
  const[cages, setCages] = useState([]) ;
  const[wards, setWards] = useState([]);
  const[hospitals, setHospitals] = useState([]);
  const[currentscreen, setCurrentscreen] = useState([])



  useEffect(() => {

    //getHospcode();
    getParentcode();
    getlocationinfo();
    //setHospcode();

  }, [setSatellites, hospitalcode] );



  async function getledgerentry(row1) {

    console.log('getledgerentry');

    let apiName = 'saveledgerentry';
    let path = '/getinvlevelentry';

    const myInit = { // OPTIONAL
      body: {
        pk1: 'INVLEVEL#PARENTCODE#' + hospitalcode + 'HOSPITALCODE#' + hospitalcode,
        pk2: row1.vendorcatalognumber,
      },
      headers: {
        //'Content-Type' : 'application/json'
      },
    };

    let resp1 = await API.post(apiName, path, myInit );
    console.log('##FOUND RESP1', resp1.body.Items.length > 0  );

    if( resp1.body.Items.length > 0  ){
      updateinventorylevel( row1 ) ;
    }else{
      updateinsertinventorylevel(row1);
      //saveledgerentry( row1 ) ;
    }

    console.log(resp1);
  }


  async function saveledgerentry(row1) {
    console.log('saveledgerentry');

    let apiName = 'saveledgerentry';
    let path = '/saveledgerentry';

    const myInit = { // OPTIONAL
      body: {
        pk1: 'INV#PARENTCODE#' + hospitalcode + 'HOSPITALCODE#' + hospitalcode,
        pk2: new Date(),
        vendorcatalognumber:row1.vendorcatalognumber,
        quantity:  parseInt(row1.qty,10 ),
        description: row1.description,
        // pk2:'Mur',
        // vendorcatalognumber:'VC000123',
        // quantity: '23'
      },
      headers: {
        //'Content-Type' : 'application/json'
      },
    };

    let resp1 = await API.post(apiName, path, myInit );
    row1.saved = 1 ;
    console.log(resp1);

    //let resp2 = updateinventorylevel(row1);
    //console.log('resp2', resp2);

  }

  async function updateinsertinventorylevel(row1) {
    console.log('updateinventorylevel');

    let apiName = 'saveledgerentry';
    let path = '/updateinvlevel';

    const myInit = { // OPTIONAL
      body: {
        pk1: 'INVLEVEL#PARENTCODE#' + hospitalcode + 'HOSPITALCODE#' + hospitalcode,
        pk2: row1.vendorcatalognumber,
        vendorcatalognumber:row1.vendorcatalognumber,
        quantity:  parseInt(row1.qty,10 ),
        description: row1.description,

      },
      headers: {
        //'Content-Type' : 'application/json'
      },
    };

    let resp1 = await API.post(apiName, path, myInit );
    row1.saved = 1 ;
    console.log(resp1);

  }

  async function updateinventorylevel(row1) {
    console.log('updateinventorylevel');

    let apiName = 'saveledgerentry';
    let path = '/updateinvlevel';

    const myInit = { // OPTIONAL
      body: {
        pk1: 'INVLEVEL#PARENTCODE#' + hospitalcode + 'HOSPITALCODE#' + hospitalcode,
        pk2: row1.vendorcatalognumber,
        vendorcatalognumber:row1.vendorcatalognumber,
        quantity:  parseInt(row1.qty,10 ),
        description: row1.description,

      },
      headers: {
        //'Content-Type' : 'application/json'
      },
    };

    let resp1 = await API.put(apiName, path, myInit );
    row1.saved = 1 ;
    console.log(resp1);

  }

  async function removehealthcode() {

    const user = await Auth.currentAuthenticatedUser();

    user.deleteAttributes(['custom:healthsystem'], (err) => {
      if (err) {
        throw err;
      }
    });

  }

  async function setHospcode() {

    console.log('setHospcode');


    const user = await Auth.currentAuthenticatedUser();

    console.log(' @@@@USER setHospcode USER', user );



    const result = await Auth.updateUserAttributes(user, {
      'custom:healthsystem': 'C1008'
    });
    console.log(' @@@@@@result', result );

  }

  async function getParentcode() {
    console.log('getParentcode');
    const user = await Auth.currentAuthenticatedUser();

    console.log('####USER', user );

    let authinfo = await Auth.currentUserInfo();

    console.log('####AUTHINFO', authinfo );


    const favoriteFlavor = authinfo.attributes['custom:healthsystem'] ;
    console.log('####favoriteFlavor', favoriteFlavor );

    setParentcode( favoriteFlavor );
    //setHospitalcode( favoriteFlavor );

  }

  async function getlocationinfo() {

    console.log('##1- getlocationinfo', hospitalcode);

    let apiName = 'getlocinfo';
    let path = '/getlocinfo';

    const myInit = { // OPTIONAL
      body: {
        pccode: parentcode,
      },
      headers: {
        //'Content-Type' : 'application/json',
        // Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    };

    console.log('2- getlocationinfo');
    //console.log(API.endpoint(apiName));

    let resp1 = await API.post(apiName, path, myInit );
    console.log(resp1);
    //setRows( [...resp1.body.Items] ) ;


    let optionItemsCage = resp1.body.Items.filter( function (e) {
      return e.pk2.startsWith('CAGE#');
    }).map( (e2) =>
        <MenuItem key={e2.pk2} value={e2.pk2}>{e2.friendlyname}</MenuItem>
    );
    optionItemsCage.push  ( <MenuItem key='---' value='---'>SELECT CAGE</MenuItem>)
    setCages(optionItemsCage);

    //wards
    let optionItemsWard = resp1.body.Items.filter( function (e) {
      return e.pk2.startsWith('WARD#');
    }).map( (e2) =>
        <MenuItem key={e2.pk2} value={e2.pk2}>{e2.friendlyname}</MenuItem>
    );
    optionItemsWard.push  ( <MenuItem key='---' value='---'>SELECT WARD</MenuItem>)
    setWards(optionItemsWard);

    //hosps
    let optionItemsHospital = resp1.body.Items.filter( function (e) {
      return e.pk2.startsWith('HOSP#');
    }).map( (e2) =>
        <MenuItem key={e2.pk2} value={e2.pk2}>{e2.friendlyname}</MenuItem>
    );
    optionItemsHospital.push  ( <MenuItem key='---' value='---'>SELECT HOSP</MenuItem>)
    setHospitals(optionItemsHospital);
    console.log('#####optionItemsHospitaal', optionItemsHospital) ;

    let optionItemsSat = resp1.body.Items.filter( function (e) {
      return e.pk2.startsWith('SAT#');
    }).map( (e2) =>
        <MenuItem key={e2.pk2} value={e2.pk2}>{e2.friendlyname}</MenuItem>
    );
    optionItemsSat.push ( <MenuItem key='---' value='---'>SELECT SAT</MenuItem>)
    setSatellites( optionItemsSat ) ;

  }

  async function getppeitembydescription() {
    console.log('1- querybydescription');
    console.log('2 - SEARCH TEXT', searchtext);


    let apiName = 'getppeitem';
    let path = '/getppeitembydescription';

    const myInit = { // OPTIONAL
      body: {
        pk1: "C1008",
        sk1: '',
        description: searchtext
      },
      headers: {
        //'Content-Type' : 'application/json',
        // Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    };

    console.log('2- getppeitembydescription');
    //console.log(API.endpoint(apiName));

    let resp1 = await API.post(apiName, path, myInit );
    console.log(resp1);
    setRows( [...resp1.body.Items] ) ;

  }

  async function searchDescription() {
    console.log('1- searchDescription');
    console.log('2 - search Description', searchtext);

    let apiName = 'getppeitem';
    let path = '/getppeitem';

    const myInit = { // OPTIONAL
      body: {
        pk1: "C1008",
        sk1: searchtext,
      },
      headers: {
        //'Content-Type' : 'application/json',
        // Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    };

    console.log('2- getppeitem');
    //console.log(API.endpoint(apiName));

    let resp1 = await API.post(apiName, path, myInit );
    console.log(resp1);
    //const returnedrows = [...resp1.body.Items] ;

    let somerows = [...resp1.body.Items] ;


  }




  async function search() {
    console.log('1- getppeitem');
    console.log('2 - SEARCH TEXT', searchtext);


    let apiName = 'getppeitem';
    let path = '/getppeitem';

    const myInit = { // OPTIONAL
      body: {
        pk1: "C1008",
        sk1: searchtext,
      },
      headers: {
        //'Content-Type' : 'application/json',
        // Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    };

    console.log('2- getppeitem');
    //console.log(API.endpoint(apiName));

    let resp1 = await API.post(apiName, path, myInit );
    console.log(resp1);
    //const returnedrows = [...resp1.body.Items] ;

    if( resp1.body.Items && resp1.body.Items.length >0 ){
      console.log('response with vencat search - done');

      setRows( [...resp1.body.Items] ) ;
    }
    else{
      console.log('NO response trying description');
      getppeitembydescription();
    }
    setSearchtext('');
  }

  const handleWardchange = (val ) => {
    setWardselect(val.target.value);
  }

  const handleChangecage = ( val ) => {
    setCageselect( val.target.value );
  }

  const handleChangeHospital = ( val1  ) => {
    console.log('dropdown change with', val1 );
    console.log('dropdown change with', val1.target.value );
    setSite( val1.target.value );
  };

  const handleChange2 = (val2 ) => {
    console.log('###change###');
    if( val2.target.value ) {
      setSearchtext( val2.target.value.toUpperCase() );
    }
    else{
      setSearchtext('');
    }
  };

  const handleChangeSatellite = (val3) => {
    console.log('###change###');
    setSatelliteSelect(val3.target.value);
    //setSelectsatellite(val3.target.value );
  };

  const handleDetailButton = () =>  {
    //setDetailTabledisplay( false );
    console.log('APP Handle Detail Button ');
  }

  const handleButtonClickRemove = (prop) => (val) =>  {
    console.log('handleButtonClickRemove - prop: ', prop );
    let currow = prop ;

    let arrrowsforsave = [...rowsforsave]; // make a separate copy of the array

    let ar2 = arrrowsforsave.filter( item => item.vendorcatalognumber !== currow.vendorcatalognumber  );

      setRowsforsave( ar2 );
    }


  const handleButtonclick = (prop) => (val) =>  {

    console.log('handleButtonclick - prop: ', prop );

    console.log('####prop', prop );
    setRow( prop );

    if( prop.qty && /^\d+$/.test(prop.qty)  ){
      console.log('adding');


      setSearchtext('');
      setRowsforsave(rowsforsave);
      setRows(...[]);
    }
  }

  const handleqtyfield = (prop) => (val) => {
    //console.log('Table Says QTY: ', row );
    console.log('From App.js Table Says QTY: ', val.target.value );
    console.log('prop: ', prop );
    let currow = prop;
    currow.qty = val.target.value;
    console.log( 'currow after qty: ', currow);
  }



  function createData(description, vendorcatalognumber, manufacturernumber ) {
    return { description, vendorcatalognumber, manufacturernumber  };
  }




  const history = useHistory();

  return (
      <div  className="App" >

        <header className="full-screen-header">
          <h1 className="titleh">Qwantify</h1>

          <span className="subtitleh">PPE TRACKING v: {packageJson.version}</span>
        </header>


        <main className="content" id="MAIN_CONTENT">

          <div className="buttontitlestrip">

          <div><h3>{currentscreen}</h3></div><h4>{hospitalcode}</h4><div className="subTitle1">

            <button className="receivebutton" variant="contained" color="primary"  onClick={ async () => {
              console.log('CAGETRANSFER');
              history.push("/Cagetransfer");
              setCurrentscreen('CageTransfer') ;

            }} >
              CAGE XFR
            </button>

            <button className="receivebutton" variant="contained" color="primary"  onClick={ async () => {
              console.log('REPORTS');
              history.push("/Reports");
              setCurrentscreen('REPORTS') ;

            }} >
              REPORTS
            </button>

            <button className="adjustbutton" variant="contained" color="primary"  onClick={ async () => {
              console.log('ADJUSTMENTS');

              //setHospcode();
              //removehealthcode();
              //console.log('called remove healthcode ');
              history.push("/Adjustments");
              setCurrentscreen('ADJUSTMENTS') ;

            }} >
              ADJUSTMENTS
            </button>

            <button className="receivebutton" variant="contained" color="primary"  onClick={ async () => {
              console.log('RECEIVE');

              history.push("/Receive");
              setCurrentscreen('RECEIVE') ;

            }} >
              RECEIVE
            </button>
            <button className="pickbutton" variant="contained" color="primary"  onClick={ async () => {
              console.log('PICKBUTTON');
                history.push("/Pick");
                setCurrentscreen('PICK') ;
              //loadpick();

              //getppeitembydescription();

            }} >
              PICK
            </button>

          </div>
          </div>

          <Switch>
            <Route path='/Receive' render={ (props) => <Receive {...props} /> }  />
            <Route path='/Pick' component={Pick} />
            <Route path='/Reports' component ={Reports} />
            <Route path='/Adjustments' component ={Adjustments} />
            <Route path='/Useradmin' component ={Useradmin} />
            <Route path='/Cagetransfer' component ={Cagetransfer} />
            <Route path='/Countonly' render={ (props) => <Countonly {...props} /> }  />


            {/*<Route exact path="/Pick" render={(props) => <Pick {...props}  />} />*/}
            {/*<Route exact path="/Pick" render={(props) => <Pick loadpick={loadpick}  />} />*/}
          </Switch>

        </main>
        <footer></footer>

      </div>
  );
}



export default withAuthenticator(App, true);
