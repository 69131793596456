import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Checkbox} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});



export default function TableReports( props ) {

    const {
        handleqtyfield,
        handleButtonclick,
        rows,
        myMap
    } = props

    const classes = useStyles();

    let qty;
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Vendor Cat</TableCell>
                        <TableCell align="left"> QTY </TableCell>
                        <TableCell align="left"> HOSP TOTAL  </TableCell>
                        <TableCell align="left">Description</TableCell>
                        <TableCell align="left">Hospital Number</TableCell>
                        <TableCell align="left">Unit Of Issue</TableCell>
                        <TableCell align="left">Cage</TableCell>
                        <TableCell align="left">Cage TO</TableCell>
                        <TableCell align="left">Ward</TableCell>
                        <TableCell align="left">STATUS</TableCell>
                        <TableCell align="left">TIMESTAMP</TableCell>


                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (

                        <TableRow key={row.rowindexer }>
                            <TableCell align="left">{row.vendorcatalognumber }</TableCell>
                            <TableCell align="left">{row.quantity }</TableCell>
                            <TableCell align="left">{ myMap.get(row.vendorcatalognumber)  }</TableCell>
                            <TableCell align="left">{row.description}</TableCell>
                            <TableCell align="left">{row.stocknumber}</TableCell>
                            <TableCell align="left">{row.unitofpurchase}</TableCell>
                            <TableCell align="left">{row.cage}</TableCell>
                            <TableCell align="left">{row.cageto}</TableCell>
                            <TableCell align="left">{row.ward}</TableCell>

                            <TableCell align="left">{row.itemstatus}</TableCell>
                            <TableCell align="left">{row.pk2 }</TableCell>


                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
